import { getWithParams } from "../../api/axiosClient";

export async function getAllTaskReports(
    sort: string,
    filter: string,
    kind: string,
    team: number,
    user: number,
    task: number,
    category: string,
    search: string,
    startdate: string,
    enddate: string,
    page: number,
    pagesize: number = 10
): Promise<any> {
    let taskReports = {
        reports: [],
        categories: [],
        pageCount: 0,
        reportCount: 0
    };

    await getWithParams("taskReports/all", {
        sort,
        filter,
        kind,
        team,
        user,
        task,
        category,
        search,
        startdate,
        enddate,
        page,
        pagesize
    })
        .then((res: any) => {
            if (res?.data?.success) {
                taskReports.reports = res.data.reports;
                taskReports.categories = res?.data?.availableCategories;
                taskReports.pageCount = res?.data?.pageCount;
                taskReports.reportCount = res?.data?.reportCount;
            } else
                throw new Error(res?.message);
        })
        .catch((error: any) => {
            throw error;
        });

    return taskReports;
}