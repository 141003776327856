import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Avatar,
	Badge,
	Box,
	Center,
	Divider,
	FormControl,
	Grid,
	HStack,
	Icon,
	Input,
	Select,
	SlideFade,
	Stack,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useBreakpointValue,
	VStack,
} from "@chakra-ui/react";
import {
	BsClock,
	BsArrowDown,
	BsArrowUp,
	BsExclamationCircle,
	BsDashCircle,
	BsCheckCircle,
	BsPlayCircle,
	BsStopCircle,
} from "react-icons/bs";

import { InsightActionCard } from "../common/InsightActionCard";

import critical from "../../assets/images/incident.svg";
import overdue from "../../assets/images/waiting.svg";
import assignedToMe from "../../assets/images/confirm.svg";

import { SmallFilterCard } from "../common/SmallFilterCard";
import { isEmpty, isNull } from "lodash";
import { TeamCombobox } from "../common/TeamCombobox";
import { localDate } from "../../helpers/DayJsHelper";
import { useTable, useSortBy, usePagination } from "react-table";
import { Pager } from "../common/Pager";
import { useDebounce } from "../common/useDebounce";
import { getAllActions } from "../../features/riskAssessments/riskAssessments.actions";
import nearlyDue from "../../assets/images/nothingToSee.svg";

const RiskAssessmentActions = () => {
	const navigateTo = useNavigate();
	const { state }: any = useLocation();
	const isMobile = useBreakpointValue({ base: true, xl: false });

	const [criticalCount, setCriticalCount] = useState(0);
	const [overdueCount, setOverdueCount] = useState(0);
	const [assignedToMeCount, setAssignedToMeCount] = useState(0);
	const [nearlyDueCount, setNearlyDueCount] = useState(0);

	const [firstTimeLoading, setFirstTimeLoading] = useState(true);
	const [loading, setLoading] = useState<boolean>(false);

	const [newPageCount, setNewPageCount] = useState(0);
	const [newPageIndex, setNewPageIndex] = useState(0);

	const [categories, setCategories] = useState([]);
	const [teams, setTeams] = useState([]);

	const [filter, setFilter] = useState<string>(
		state !== null && state.filter ? state.filter : ""
	);

	const [team, setTeam] = useState(0);
	const [category, setCategory] = useState("");
	const [search, setSearch] = useState("");
	const [sort, setSort] = useState("");

	const [currentItems, setCurrentItems] = useState<any>([]);

	const fetchData = useCallback(async ({ sort, filter, team, category, search, pageIndex }) => {
		pageIndex = (pageIndex + 1).toString();

		setLoading(true);

		try {
			await getAllActions(sort, filter, search, team, category, pageIndex)
				.then(async (res: any) => {
					setNewPageCount(res.pageCount);
					setCategories(res.availableCategories);
					setTeams(res.availableTeams);
					setCriticalCount(res.criticalCount);
					setOverdueCount(res.overdueCount);
					setAssignedToMeCount(res.assignedToMeCount);
					setNearlyDueCount(res.nearlyDueCount);
					setCurrentItems(res.actions);
				});

		} catch (error: any) {
			console.log(error);
		} finally {
			setLoading(false);
			setFirstTimeLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchData(
			{
				sort: sort,
				filter: filter,
				team: team,
				category: category,
				search: search,
				pageIndex: newPageIndex
			}
		);
	}, [fetchData, filter, team, category, newPageIndex, sort]);

	const debouncedRequest = useDebounce(() => {
		setNewPageIndex(0);

		fetchData(
			{
				sort: sort,
				filter: filter,
				team: team,
				category: category,
				search: search,
				pageIndex: 0
			}
		)
	}, 300);

	const updateSearch = (e: any) => {
		setSearch(e.target.value);
		debouncedRequest();
	};

	function setClearAllFiltersPressed() {
		setFilter("all");
		setNewPageIndex(0);
		setSearch("");
		setCategory("");
		setTeam(0);
	}

	const columns = useMemo(
		() => [
			{
				Header: "",
				accessor: "raaaction",
				disableSortBy: true,
			},
			{
				Header: "",
				accessor: "isBlocked",
				disableSortBy: true,
			},
			{
				Header: "",
				accessor: "overdue",
				disableSortBy: true,
			},
			{
				Header: "",
				accessor: "isCritical",
				disableSortBy: true,
			},
			{
				Header: "",
				accessor: "nearlydue",
				disableSortBy: true,
			},
			{
				Header: "",
				accessor: "teamid",
				disableSortBy: true,
			},
			{
				Header: <>
					ACTION {
						sort === "raadescription" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "raadescriptiondesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "raadescription",
				Cell: (cell: any) => <VStack alignItems="start">
					<HStack>
						<Text
							textAlign="start"
							fontSize="sm"
							color="gray.600"
							textOverflow="ellipsis"
							maxWidth={"400px"}
						>
							{
								cell.row.original.isCritical && <><Icon
									as={BsExclamationCircle}
									color="danger.500"
									fontSize="xs"
								/>&nbsp;</>
							}
							{cell.value || "Untitled"}
						</Text>
					</HStack>
				</VStack>
			},
			{
				Header: <>
					RISK ASSESSMENT {
						sort === "raname" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "ravnamedesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "raname",
				Cell: (cell: any) => <VStack alignItems="start">
					<HStack>
						<Text
							textAlign="start"
							fontSize="sm"
							color="gray.600"
							textOverflow="ellipsis"
							maxWidth={"400px"}
						>
							{cell.value || "Untitled"}
						</Text>
					</HStack>
				</VStack>
			},
			{
				Header: <>
					CATEGORY {
						sort === "racategory" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "racategorydesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "racategory",
				Cell: (cell: any) => <Text
					color="gray.600"
					fontSize="sm"
				>
					{cell.value}

				</Text>
			},
			{
				Header: <>
					ASSIGNED TO {
						sort === "teamname" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "teamnamedesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "teamname",
				Cell: (cell: any) => {
					const username = isNull(cell.row.original.action?.userforename) ? "" : `${cell.row.original.action?.userforename} ${cell.row.original.action?.usersurname}`;
					return <>
						<HStack>
							<Avatar
								size="xs"
								color="white"
								name={cell.row.original.unassigned && isEmpty(username) && isEmpty(cell.value) ? "" : username || cell.value}
							/>
							<Text
								textAlign="start"
								fontWeight={500}
								fontSize="sm"
								color="gray.600"
							>
								{cell.row.original.unassigned && isEmpty(username) && isEmpty(cell.value) ? "Unassigned" : username || cell.value}
							</Text>
						</HStack>
					</>
				}
			},
			{
				Header: <>
					CREATED {
						sort === "datecreated" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "datecreateddesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "action.actiondatecreated",
				Cell: (cell: any) => <Text
					color="gray.600"
					fontSize="sm"
				>
					{localDate(cell.value)}
				</Text>
			},
			{
				Header: <>
					DUE {
						sort === "duedate" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "duedatedesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "action.actionduedate",
				Cell: (cell: any) =>
					<Text
						hidden={isNull(cell.value)}
						color="gray.600"
						fontSize="sm"
					>
						Due {localDate(cell.value)}
					</Text>
			},
			{
				Header: <>
					STATUS {
						sort === "statustext" ?
							<Icon as={BsArrowUp} color="gray.600" /> :
							sort === "statustextdesc" ?
								<Icon as={BsArrowDown} color="gray.600" /> :
								<></>
					}
				</>,
				accessor: "statustext",
				Cell: (cell: any) => {
					return <>
						<Badge
							size="sm"
							color={
								cell.row.original?.action?.actionstatus === "0" ? "gray.700" :
									cell.row.original?.action?.actionstatus === "1" ? "telegram" :
										cell.row.original?.action?.actionstatus === "2" ? "red.700" :
											cell.row.original?.action?.actionstatus === "99" ? "green.700" :
												"gray.700"
							}
							colorScheme={
								cell.row.original?.action?.actionstatus === "0" ? "gray" :
									cell.row.original?.action?.actionstatus === "1" ? "telegram" :
										cell.row.original?.action?.actionstatus === "2" ? "danger" :
											cell.row.original?.action?.actionstatus === "99" ? "green" :
												"gray"
							}
						>
							<HStack>
								<Icon
									as={
										cell.row.original?.action?.actionstatus === "0" ? BsDashCircle :
											cell.row.original?.action?.actionstatus === "1" ? BsPlayCircle :
												cell.row.original?.action?.actionstatus === "2" ? BsStopCircle :
													cell.row.original?.action?.actionstatus === "99" ? BsCheckCircle :
														BsDashCircle
									}
								/>
								<Text fontWeight={500} fontSize={{ base: "8pt", md: "xs" }}>
									{cell.row.original?.action?.statustext}
								</Text>
							</HStack>
						</Badge>
					</>
				}
			}
		], [sort]
	);

	const getActionAssignedMember = (task: any) => {
		const username = isNull(task?.action?.userforename) ? "" : `${task?.action?.userforename} ${task?.action?.usersurname}`;

		if (task.unassigned &&
			isEmpty(username) &&
			isEmpty(task.teamname)
		) {
			return ""
		} else
			return isNull(task?.action?.userforename) ? task.teamname : `${task.action?.userforename} ${task.action?.usersurname}`
	};

	const sortColumn = (term: string) => {
		switch (term) {
			case "raadescription":
				if (sort === "raadescription") {
					setSort("raadescriptiondesc");
				} else if (sort === "raadescriptiondesc") {
					setSort("raadescription");
				} else setSort("raadescriptiondesc");
				break;
			case "ravname":
				if (sort === "ravname") {
					setSort("ravnamedesc");
				} else if (sort === "ravnamedesc") {
					setSort("ravname");
				} else setSort("ravnamedesc");
				break;
			case "racategory":
				if (sort === "racategory") {
					setSort("racategorydesc");
				} else if (sort === "racategorydesc") {
					setSort("racategory");
				} else setSort("racategorydesc");
				break;
			case "statustext":
				if (sort === "statustext") {
					setSort("statustextdesc");
				} else if (sort === "statustextdesc") {
					setSort("statustext");
				} else setSort("statustextdesc");
				break;
			case "action.actiondatecreated":
				if (sort === "datecreated") {
					setSort("datecreateddesc");
				} else if (sort === "datecreateddesc") {
					setSort("datecreated");
				} else setSort("datecreateddesc");
				break;
			case "action.actionduedate":
				if (sort === "duedate") {
					setSort("duedatedesc");
				} else if (sort === "duedatedesc") {
					setSort("duedate");
				} else setSort("duedatedesc");
				break;
			case "teamname":
				if (sort === "teamname") {
					setSort("teamnamedesc");
				} else if (sort === "teamnamedesc") {
					setSort("teamname");
				} else setSort("teamnamedesc");
				break;
		}
	};

	function DataTable({
		data,
		columns,
		loading,
		pageCount: controlledPageCount,
		onHeaderClick
	}: any) {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			page,
			prepareRow,
		} = useTable(
			{
				columns,
				data,
				manualPagination: true,
				manualSortBy: true,
				pageCount: controlledPageCount,
				onHeaderClick,
				autoResetPage: false,
				initialState: {
					hiddenColumns: ["raaaction", "isBlocked", "overdue", "isCritical", "nearlydue", "teamid"]
				}
			},
			useSortBy,
			usePagination
		);

		return (
			<SlideFade in={!loading}>
				<VStack
					display={{ base: "flex", lg: "none" }}
					mb={currentItems.length > 6 ? 5 : 0}
				>
					{
						currentItems.map((task: any, i: number) => (
							<Box
								key={i}
								w="100%"
								className="hover-pop"
								cursor="pointer"
								onClick={() => {
									if (!teams.some((t: any) => t.teamid === task?.teamid)) return;

									navigateTo(`/actions/action/${task.raaaction}`, {
										state: {
											from: "riskassessmentactions"
										}
									})

								}}
							>
								<Box
									w="100%"
									minH="2px"
									py="1px"
									roundedTop="md"
									bg={
										task.action.overdue ? "red.500" :
											task.action.nearlydue ? "yellow.500" :
												"green.500"
									}
								>
									<HStack hidden={!task.action.overdue && !task.action.nearlydue} alignContent="end">
										<HStack mx={3}>
											<Icon as={task.action.overdue ? BsExclamationCircle : BsClock} fontSize="xs" color="white" />
											<Text fontSize="xs" color="white" fontWeight={600}>
												{task.action.overdue ? "Overdue" : "Due Soon"}
											</Text>
										</HStack>
									</HStack>
								</Box>

								<Box
									p={3}
									pt={2}
									w="full"
									boxShadow="xs"
									roundedBottom="lg"
									border="1px solid"
									borderColor="gray.100"
								>
									<VStack alignItems="start">
										<Text
											fontSize="xs"
											fontWeight={600}
											textAlign="start"
											color="gray.600"
										>
											{task?.raadescription}
										</Text>

										<Text
											color="gray.600"
											fontWeight={500}
											fontSize="xs"
										>
											{task.statustext}
										</Text>

										<HStack w="100%" gap={12}>
											<Box alignItems="start">
												<Text
													fontSize="8pt"
													fontWeight={600}
													textAlign="start"
													color="gray.600"
												>
													Risk Assessment
												</Text>

												<Text
													fontSize="xs"
													fontWeight={500}
													textAlign="start"
													color="gray.500"
												>
													{task?.raname}
												</Text>
											</Box>

											<Box alignItems="start">
												<Text
													fontSize="8pt"
													fontWeight={600}
													textAlign="start"
													color="gray.600"
												>
													Due Date
												</Text>

												<Text
													fontSize="xs"
													fontWeight={500}
													textAlign="start"
													color="gray.500"
												>
													{localDate(task.action.actionduedate) || "-"}
												</Text>
											</Box>
										</HStack>

										<HStack w="100%" gap={12}>
											<HStack>
												<Avatar
													size="2xs"
													color="white"
													name={getActionAssignedMember(task)}
												/>
												<Text
													textAlign="start"
													fontWeight={500}
													fontSize="xs"
													color="gray.600"
												>
													{getActionAssignedMember(task)}
												</Text>
											</HStack>
										</HStack>
									</VStack>
								</Box>
							</Box>
						))
					}
				</VStack>

				<Table
					{...getTableProps()}
					id="RiskAssessmentActionsTable"
					variant="striped"
					display={{ base: "none", lg: "inline-table" }}
					table-layout="fixed"
					width={"100%"}
				>
					<Thead>
						{
							page.length != 0 && headerGroups.map((headerGroup, i) => (
								<Tr {...headerGroup.getHeaderGroupProps()}>
									{
										headerGroup.headers.map((column, i) => (
											<Th
												{...column.getHeaderProps(column.getSortByToggleProps())}
												onClick={() => onHeaderClick(column)}
												key={`task_header_${i}`}
												style={{ backgroundColor: 'white', border: 'none' }}
											>
												<Text fontWeight={700} color="gray.600">
													{column.render("Header")}
												</Text>
											</Th>
										))
									}
								</Tr>
							))
						}
					</Thead>

					<Tbody {...getTableBodyProps()}>
						{
							page.length === 0 ?
								<Center m={5}>
									<VStack>
										<Text fontSize="xl" fontWeight={600}>
											No Risk Assessment Actions Found
										</Text>
										<Text fontWeight={400} color="gray.500">
											You have no risk assessment actions available
										</Text>
									</VStack>
								</Center>
								:
								page.map((row, i) => {
									prepareRow(row);

									return (
										<Tr
											{...row.getRowProps()}
											className={!teams.some((t: any) => t.teamid === row.values?.teamid) ? "disabled-row" : "hover-pop"}
											key={i}
											borderBottom="1px solid"
											borderColor="gray.100"
											bg={row.values?.isBlocked || row.values?.overdue || row.values?.isCritical ? "red.50" : row.values?.nearlydue ? "orange.50" : "white"}
											cursor={!teams.some((t: any) => t.teamid === row.values?.teamid) ? "default" : "pointer"}
											rounded="lg"
											onClick={() => {
												if (!teams.some((t: any) => t.teamid === row.values?.teamid)) return;

												navigateTo(`/actions/action/${row.values.raaaction}`, {
													state: {
														from: "riskassessmentactions"
													}
												})
											}}
										>
											{
												row.cells.map((cell) => {
													return (
														<Td  {...cell.getCellProps()} style={{ whiteSpace: 'normal', wordWrap: 'break-word', minWidth: '120px' }}>
															{cell.render("Cell")}
														</Td>
													);
												})
											}
										</Tr>
									);
								})
						}
					</Tbody>
				</Table>

				{
					newPageCount > 1 && <Box
						mt={3}
						textAlign="center"
					>
						<Pager
							pageCount={newPageCount || 0}
							pageIndex={newPageIndex || 0}
							setPageIndex={setNewPageIndex}
						/>
					</Box>
				}
			</SlideFade>
		);
	}

	return (
		<Box>
			<Box>
				<>
					<Grid
						templateColumns={{
							base: "repeat(2, 2fr)",
							md: "repeat(4, 1fr)",
						}}
						gap={isMobile ? 2 : 3}
						mb={isMobile ? 2 : 5}
					>
						<InsightActionCard
							title="Critical Actions"
							count={criticalCount || 0}
							image={critical}
							color="danger.500"
							active={filter === "critical"}
							className="hover-pop"
							cursor="pointer"
							onClickAction={() => {
								if (filter === "critical") {
									setFilter("all");
									setNewPageIndex(0);
								} else {
									setFilter("critical");
									setNewPageIndex(0);
								}
							}}
							loading={firstTimeLoading}
						/>

						<InsightActionCard
							title="Overdue Actions"
							count={overdueCount || 0}
							image={overdue}
							color="orange.500"
							active={filter === "overdue"}
							className="hover-pop"
							cursor="pointer"
							onClickAction={() => {
								if (filter === "overdue") {
									setFilter("all");
									setNewPageIndex(0);
								} else {
									setFilter("overdue");
									setNewPageIndex(0);
								}
							}}
							loading={firstTimeLoading}
						/>

						<InsightActionCard
							title="Assigned to me"
							count={assignedToMeCount || 0}
							image={assignedToMe}
							color="#805AD5"
							active={filter === "assignedtome"}
							className="hover-pop"
							cursor="pointer"
							onClickAction={() => {
								if (filter === "assignedtome") {
									setFilter("all");
									setNewPageIndex(0);
								} else {
									setFilter("assignedtome");
									setNewPageIndex(0);
								}
							}}
							loading={firstTimeLoading}
						/>
						<InsightActionCard
							title="Nearly Due"
							count={nearlyDueCount || 0}
							image={nearlyDue}
							color="#805AD5"
							active={filter === "nearlydue"}
							className="hover-pop"
							cursor="pointer"
							onClickAction={() => {
								if (filter === "nearlydue") {
									setFilter("all");
									setNewPageIndex(0);
								} else {
									setFilter("nearlydue");
									setNewPageIndex(0);
								}
							}}
							loading={firstTimeLoading}
						/>
					</Grid>

					{
						!isMobile && <Divider w="unset" mx={{ base: 0, md: -10 }} mb={5} />
					}

					<SlideFade in={!firstTimeLoading} offsetY="20px">
						{
							categories.length > 0 && categories.length <= 7 &&
							<Box
								p={1.5}
								bg="gray.50"
								textAlign="left"
								rounded="lg"
								border="1px solid"
								borderColor="gray.200"
							>
								<Text
									fontWeight={isMobile ? 600 : 500}
									color="gray.500"
									fontSize={isMobile ? "xs" : 'sm'}
									ml={1}
									mb={2}
								>
									Risk Assessment Categories
								</Text>

								<SlideFade in={!firstTimeLoading}>
									<HStack>
										{
											categories.map((c: any, i: Number) => {
												return (
													<SmallFilterCard
														key={i}
														title={c.racategory}
														onClickAction={() => {
															setNewPageIndex(0);

															c.racategory !== category
																? setCategory(c.racategory)
																: setCategory("");
														}}
														selectedCard={category}
														icon={BsExclamationCircle}
														iconColor="brand.500"
													/>
												);
											})
										}
									</HStack>
								</SlideFade>
							</Box>
						}
					</SlideFade>

					<SlideFade in={!firstTimeLoading} offsetY="20px">
						<Stack
							direction={{ base: "column", lg: "row" }}
							mt={
								categories.length > 0 && categories.length <= 7 ? 2 :
									categories.length > 7 ? 5 : 5
							}
						>
							{
								categories.length > 7 ?
									<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }}>
										<Select
											_focus={{ borderColor: "brand.500", borderWidth: "1px" }}
											color="gray.600"
											fontSize="sm"
											defaultValue={
												state !== null && state.teamId ? Number(state.teamId) : ""
											}
											onChange={(e) => {
												setNewPageIndex(0);
												setCategory(e.target.value);
											}}
											placeholder="Search by Category"
										>
											{
												categories.map((c: any, i) => {
													return <option key={i} value={c.racategory}>{c.racategory}</option>;
												})
											}
										</Select>
									</FormControl> :

									<FormControl w={{ base: "full", lg: "50%" }}>
										<Input
											placeholder="Search Actions"
											rounded="md"
											fontSize="sm"
											value={search}
											onChange={updateSearch}
										/>
									</FormControl>
							}

							{
								categories.length > 7 &&
								<FormControl w={{ base: "full", lg: "50%" }}>
									<Input
										placeholder="Search Actions"
										rounded="md"
										fontSize="sm"
										value={search}
										onChange={updateSearch}
									/>
								</FormControl>
							}

							<FormControl colorScheme="green" w={{ base: "full", lg: "50%" }} hidden={isEmpty(teams)}>
								<TeamCombobox
									disabled={isEmpty(teams)}
									teams={teams || []}
									selectedTeam={team}
									update={(value: any) => {
										setNewPageIndex(0);
										setTeam(value);
									}}
								/>
							</FormControl>
						</Stack>
					</SlideFade>
				</>

				<Box
					w="full"
					p={isMobile ? 0 : 3}
					mt={5}
					border={isMobile ? "none" : "1px solid"}
					borderColor="gray.100"
					borderRadius="lg"
				>
					{
						DataTable({
							data: currentItems ?? [],
							columns,
							loading,
							pageCount: 0,
							onHeaderClick: (c: any) => sortColumn(c.id)
						})
					}
				</Box>

			</Box>
		</Box>
	);
};
export default memo(RiskAssessmentActions);