import React from 'react';
import { Box, HStack, Icon, Text, Spacer, Divider, BoxProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface ObbiContainerProps extends BoxProps {
    icon: IconType;
    title: string;
    children: React.ReactNode;
    actions?: React.ReactNode;
    backLink?: React.ReactNode;
}

const ObbiContainer: React.FC<ObbiContainerProps> = ({ icon, title, children, actions, backLink, ...boxProps }) => {
    return (
        <>
            <Box mt={5} mx={5}>
                {backLink}
            </Box>

            <Box
                m={5}
                pb={5}
                px={{ base: 0, md: 5 }}
                bg="white"
                rounded={{ base: "none", md: "lg" }}
                boxShadow={{ base: "none", md: "lg" }}
                {...boxProps}
            >
                <HStack p={5}>
                    <HStack>
                        <Icon
                            as={icon}
                            mr={3}
                            verticalAlign="middle"
                            fontSize="4xl"
                            color="green.500"
                        />
                        <Text
                            color="gray.700"
                            textAlign="left"
                            fontWeight={{ base: 600, lg: 700 }}
                            fontSize={{ base: "xl", lg: "2xl" }}
                        >
                            {title}
                        </Text>
                    </HStack>
                    <Spacer />

                    {actions}
                </HStack>

                <Box mx={{ base: 2, md: 5 }}>
                    <Divider
                        color="gray.100"
                        w="unset"
                        mb={5}
                        mx={{ base: -6, md: -10 }}
                    />

                    {children}
                </Box>
            </Box>
        </>
    );
};

export default ObbiContainer;