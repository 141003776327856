import { extendTheme } from "@chakra-ui/react";
import { theme } from '@chakra-ui/pro-theme';

export const obbiTheme = extendTheme(
    {
        colors: {
            ...theme.colors,
            brand: {
                500: "#37a169",
                700: "#248451"
            },
            white: {
                500: "#ffffff"
            },
            warning: {
                500: "#d69e2e"
            },
            danger: {
                500: "#e53e3e",
                100: "#fed7d7",
                50: "#fff5f5"
            },
            darkgray: {
                500: "#718096"
            }
        }
    },
    theme
);