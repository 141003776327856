import React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    BoxProps,
    Center,
    useBreakpointValue,
    HStack,
    Icon
} from '@chakra-ui/react';
import { useTable, usePagination } from 'react-table';
import { Pager } from './Pager';
import { BsSortDown, BsSortUp, BsArrowDownUp } from 'react-icons/bs';

interface Column {
    Header: string;
    accessor: string;
    Cell?: (value: any) => React.ReactNode;
    sortKey?: string;
}

interface ObbiTableProps extends BoxProps {
    columns: Column[];
    data: any[];
    caption?: string;
    getRowProps?: (row: any) => any;
    onRowClick?: (row: any) => void;
    pageCount?: number;
    pageSize?: number;
    loading?: boolean;
    initialPage?: number;
    onPageChange?: (page: number) => void;
    sortColumn?: string;
    onSort?: (sortKey: string) => void;
}

const ObbiTable: React.FC<ObbiTableProps> = ({
    columns,
    data,
    caption,
    getRowProps,
    onRowClick,
    pageCount = 0,
    pageSize = 10,
    loading = false,
    initialPage = 0,
    onPageChange,
    sortColumn = 'datesignedoffdesc',
    onSort,
    ...boxProps
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        state: { pageIndex },
        gotoPage
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: initialPage,
                pageSize
            },
            manualPagination: true,
            pageCount: pageCount
        },
        usePagination
    );

    const isMobile = useBreakpointValue({ base: true, md: false });

    const handlePageChange = (newPage: number) => {
        gotoPage(newPage);
        onPageChange?.(newPage);
    };

    const handleSort = (column: Column) => {
        if (!column.sortKey) return;

        const isCurrentSort = sortColumn.replace('desc', '') === column.sortKey;
        const isDescending = sortColumn.endsWith('desc');
        
        const newSort = isCurrentSort
            ? isDescending
                ? column.sortKey
                : `${column.sortKey}desc`
            : column.sortKey;

        onSort?.(newSort);
    };

    const getSortIcon = (column: Column) => {
        if (!column.sortKey) return null;

        const isCurrentSort = sortColumn.replace('desc', '') === column.sortKey;
        if (isCurrentSort) {
            const isDescending = sortColumn.endsWith('desc');
            return (
                <Icon 
                    as={isDescending ? BsSortDown : BsSortUp} 
                    color="gray.700"
                />
            );
        }
        
        // Show a neutral sort icon for sortable inactive columns
        return (
            <Icon 
                as={BsArrowDownUp} 
                color="gray.400" 
                fontSize="sm"
            />
        );
    };

    return (
        <TableContainer
            p={3}
            border="1px solid"
            borderColor="gray.200"
            borderRadius="lg"
            overflow={isMobile ? "scroll" : "hidden"}
            {...boxProps}
        >
            <Table variant="unstyled" {...getTableProps()}>
                {caption && <TableCaption>{caption}</TableCaption>}

                <Thead>
                    {headerGroups.map(headerGroup => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <Th
                                    border="none"
                                    color="gray.700"
                                    fontWeight={600}
                                    fontSize="xs"
                                    cursor={column.sortKey ? "pointer" : "default"}
                                    onClick={() => handleSort(column)}
                                    _hover={{
                                        '& .sort-icon': {
                                            opacity: 1
                                        }
                                    }}
                                    {...column.getHeaderProps()}
                                >
                                    <HStack spacing={2}>
                                        <span>{column.render('Header')}</span>
                                        <span className="sort-icon" style={{
                                            opacity: column.sortKey && !sortColumn.includes(column.sortKey) ? 0.5 : 1,
                                            transition: 'opacity 0.2s'
                                        }}>
                                            {getSortIcon(column)}
                                        </span>
                                    </HStack>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        const rowProps = getRowProps ? getRowProps(row) : {};

                        return (
                            <Tr
                                {...row.getRowProps()}
                                {...rowProps}
                                key={i}
                                className='hover-pop'
                                cursor="pointer"
                                onClick={() =>
                                    onRowClick && onRowClick(row.original)
                                }
                            >
                                {row.cells.map(cell => (
                                    <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                                ))}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>

            <Center mt={4} mb={1}>
                <Pager
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    setPageIndex={handlePageChange}
                    loading={loading}
                />
            </Center>
        </TableContainer>
    );
};

export default ObbiTable;