import React, { useState, useEffect } from 'react';
import GenericCombobox from './GenericCombobox';
import { User, UserComboboxProps } from './types/TaskReportingTypes';

const UserCombobox: React.FC<UserComboboxProps> = ({
    users,
    selectedUser,
    update,
    disabled,
    filterByTeam,
    teamsWithUsers
}) => {
    const transformToUser = (value: number | User): User => {
        if (typeof value === 'number') {
            return { uduid: value, udforename: value === 0 ? 'All Users' : '' };
        }
        return value;
    };

    const [internalSelectedUser, setInternalSelectedUser] = useState<User>(
        transformToUser(selectedUser || 0)
    );

    useEffect(() => {
        setInternalSelectedUser(transformToUser(selectedUser || 0));
    }, [selectedUser]);

    const getId = (user: User): number => {
        return user?.uduid || 0;
    };

    const getDisplayValue = (user: User): string => {
        if (!user) return 'All Users';
        
        if (user.uduid === 0) return 'All Users';

        if (user.udforename && user.udsurname) {
            return `${user.udforename} ${user.udsurname}`;
        } else if (user.udforename) {
            return user.udforename;
        } else if (user.udsurname) {
            return user.udsurname;
        }
        return 'All Users';
    };

    const addDefaultUser = (users: User[]): User[] => {
        if (!users) return [{ uduid: 0, udforename: 'All Users' }];
        return [{ uduid: 0, udforename: 'All Users' }, ...users];
    };

    // Filter users based on selected team
    const getFilteredUsers = (): User[] => {
        if (!filterByTeam) return users;
        
        const teamUsers = teamsWithUsers?.find(t => t.teamid === filterByTeam)?.users || [];
        return teamUsers;
    };

    const filterFunction = (user: User, query: string): boolean => {
        // Check if user belongs to selected team
        if (filterByTeam && user.uduid !== 0) {
            const teamUsers = teamsWithUsers?.find(t => t.teamid === filterByTeam)?.users || [];
            
            if (!teamUsers.some(u => u.uduid === user.uduid)) return false;
        }

        const forename = user.udforename?.toLowerCase() || '';
        const surname = user.udsurname?.toLowerCase() || '';
        const fullName = `${forename} ${surname}`;
        const searchQuery = query.toLowerCase();

        return forename.includes(searchQuery) ||
            surname.includes(searchQuery) ||
            fullName.includes(searchQuery);
    };

    const handleUserUpdate = (user: User) => {
        const transformedUser = transformToUser(user);
        setInternalSelectedUser(transformedUser);
        update(transformedUser);
    };

    return (
        <GenericCombobox<User>
            data={addDefaultUser(getFilteredUsers())}
            selectedItem={internalSelectedUser}
            update={handleUserUpdate}
            disabled={disabled}
            placeholder='All Users'
            getId={getId}
            displayValue={getDisplayValue}
            filterFunction={filterFunction}
        />
    );
};

export default UserCombobox;