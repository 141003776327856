import {
    As,
    Badge,
    Button,
    ButtonProps,
    Flex,
    HStack,
    Icon,
    Text
} from '@chakra-ui/react';

interface NavButtonProps extends ButtonProps {
    icon: As;
    label: string;
    bgColor: string;
    color: string;
    toggleAlert: boolean;
    alertCount: number;
};

export const NavButton: React.FC<any> = (props: NavButtonProps) => {
    const { icon, label, bgColor, color, toggleAlert, alertCount, ...buttonProps } = props;

    return <Button
        _hover={{ bgColor: bgColor ? bgColor : 'gray.50' }}
        _active={{ bgColor: bgColor ? bgColor : 'gray.50' }}
        backgroundColor={bgColor}
        variant='ghost'
        justifyContent='start'
        size='sm'
        {...buttonProps}
    >
        <Flex
            align='center'
            justify='space-between'
            w='100%'
        >
            <HStack spacing={3}>
                {
                    icon && <Icon
                        as={icon}
                        boxSize={4}
                        color={color || 'gray.400'}
                    />
                }

                <Text fontSize='xs' color={color}>
                    {label}
                </Text>
            </HStack>

            {
                Number(alertCount) > 0 && <Badge
                    colorScheme={color || 'red'}
                    variant={color ? 'outline' : 'subtle'}
                    size='sm'
                    px={2}
                    ml={3}
                >
                    {alertCount}
                </Badge>
            }
        </Flex>
    </Button>
}