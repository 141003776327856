import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Datepicker from 'react-tailwindcss-datepicker';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Avatar, Badge, Box, HStack, Icon, Image, Input, Spacer, Spinner, Text, useToast, VStack, Center, Button, Stack, useBreakpointValue } from '@chakra-ui/react';
import { BsCheckCircle, BsCheckCircleFill, BsClock, BsExclamationCircle, BsEye, BsEyeFill, BsClockFill, BsXCircleFill, BsXCircle, BsSearch, BsWrench, BsExclamationTriangle, BsTrash } from 'react-icons/bs';

// Components
import ObbiTable from '../../common/ObbiTable';
import { SmallFilterCard } from '../../common/SmallFilterCard';
import CategoryCombobox from './CategoryCombobox';
import TeamUserSelection from './TeamUserSelection';

// Types
import { TaskReport } from './types/TaskReportingTypes';

// API
import { getAllTaskReports } from '../../../features/taskReports/taskReports.actions';
import { getMyTeams, getTeamMembers } from '../../../features/admin/admin.actions';

// Utils
import { localDateTime, relativeTime } from '../../../helpers/DayJsHelper';

// Images
import nothingToSee from '../../../assets/images/nothingToSee.svg';
import upload from "../../../assets/images/uploadRisk.svg";

// ToDo: extract to utilities file
const capitalizeFirstChar = (str: string) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const INITIAL_QUERY_FILTERS = {
    sort: 'datesignedoffdesc',
    filter: 'all', // scheduled | notscheduled | overdue | passed | failed | missed | all
    kind: 'all',   // team | guided | periodic | all  
    team: 0,
    user: 0,
    task: 0,
    category: '',
    search: '',
    startdate: '',
    enddate: '',
    page: 0
};

type QueryFilters = typeof INITIAL_QUERY_FILTERS;

const TaskReporting: React.FC<any> = ({ onNavigateToTaskReport }) => {
    const columns = [
        {
            Header: 'TASK',
            accessor: 'taskname',
            sortKey: 'taskname',
            Cell: ({ row }: { row: { original: TaskReport } }) => (
                <VStack alignItems='start'>
                    <Text
                        color={row.original.isfailed ? 'red.500' : row.original.ismissed ? 'orange.500' : 'gray.700'}
                        fontWeight={row.original.isfailed || row.original.ismissed ? 500 : 400}
                    >
                        {row.original.taskname}
                    </Text>
                    {
                        row.original.reportkind === '2' && (
                            <Badge
                                colorScheme={row.original.isfailed ? 'red' : row.original.ismissed ? 'orange' : 'blue'}
                                variant='outline'
                                size='sm'
                            >
                                <HStack>
                                    <Icon as={BsEye} />
                                    <Text>Observed {row.original.targetusername}</Text>
                                </HStack>
                            </Badge>
                        )
                    }
                    {
                        row.original.reportkind === '3' && (
                            <Badge
                                colorScheme={row.original.isfailed ? 'red' : row.original.ismissed ? 'orange' : 'purple'}
                                variant='outline'
                                size='sm'
                            >
                                <HStack>
                                    <Icon as={BsExclamationCircle} />
                                    <Text>Mandatory</Text>
                                </HStack>
                            </Badge>
                        )
                    }
                    <HStack>
                        <Avatar name={row.original.teamname} size='xs' color='white' />
                        <Text>{row.original.teamname}</Text>
                    </HStack>
                </VStack>
            )
        },
        {
            Header: 'RESULT',
            accessor: 'reportstatus',
            Cell: ({ row }: { row: { original: TaskReport } }) => (
                <Badge
                    variant='outline'
                    size='sm'
                    colorScheme={
                        row.original.reportstatus === '1' ? 'green' :
                            row.original.reportstatus === '2' ? 'red' :
                                row.original.reportstatus === '3' ? 'orange' : 'gray'
                    }>
                    <HStack>
                        {row.original.reportstatus === '1' ? <Icon as={BsCheckCircle} /> :
                            row.original.reportstatus === '2' ? <Icon as={BsXCircle} /> :
                                row.original.reportstatus === '3' ? <Icon as={BsClock} /> : 'Unknown'}
                        <Text>
                            {row.original.reportstatus === '1' ? 'Passed' :
                                row.original.reportstatus === '2' ? 'Failed' :
                                    row.original.reportstatus === '3' ? 'Missed' : 'Unknown'}
                        </Text>
                    </HStack>
                </Badge>
            )
        },
        {
            Header: 'COMPLETED',
            accessor: 'reportdatesignedoff',
            sortKey: 'datesignedoff',
            Cell: ({ row }: { row: { original: TaskReport } }) => (
                <VStack alignItems='start' color='gray.600'>
                    <Text>{localDateTime(row.original.reportdatesignedoff)}</Text>
                    <Text fontSize="10pt">{relativeTime(row.original.reportdatesignedoff)} by {row.original.reportsignedoffbyname}</Text>
                </VStack>
            )
        },
        {
            Header: 'EXTRA INFO',
            accessor: 'actioncount',
            Cell: ({ row }: { row: { original: TaskReport } }) => (
                <VStack alignItems={'flex-start'}>
                    {row.original.hasvariables && row.original.variables?.map((variable: any, index: number) => (
                        <HStack key={index}>
                            <Text color={variable.failed ? 'red.500' : 'inherit'}>
                                <strong>{variable.name}:</strong> {variable.value} {variable.unit}
                            </Text>
                            {variable.failed && <Icon as={BsExclamationTriangle} color='red.500' />}
                        </HStack>
                    ))}
                    {row.original.actioncount > 0 &&
                        <HStack>
                            <Text><strong>Corrective Actions:</strong> {row.original.actioncount}</Text>
                            <Icon as={BsWrench} color={'gray.600'} />
                        </HStack>
                    }
                </VStack>
            )
        },
    ];

    const { state: locationState }: any = useLocation();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const isLg = useBreakpointValue({ base: true, lg: false });
    const toast = useToast();

    const [loading, setLoading] = useState<boolean>(false);
    const [isReset, setIsReset] = useState(false);
    const [pageCount, setPageCount] = useState<number>(0);
    const [reportCount, setReportCount] = useState<number>(0);
    const [taskReports, setTaskReports] = useState<TaskReport[]>([]);
    const [teamsManaged, setTeamsManaged] = useState<any>([]);
    const [teamsWithUsers, setTeamsWithUsers] = useState<{ teamid: number, users: any }[]>([]);
    const [allUsers, setAllUsers] = useState<any>([]);
    const [availableUsers, setAvailableUsers] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);

    const [percPassed, setPercPassed] = useState<any>(0);

    const [queryFilters, setQueryFilters] = useState(() => {
        if (locationState?.navData?.filters) {
            const navFilters = locationState.navData.filters;
            return {
                ...INITIAL_QUERY_FILTERS,
                ...navFilters,
                startdate: navFilters.startdate || '',
                enddate: navFilters.enddate || ''
            };
        }

        return INITIAL_QUERY_FILTERS;
    });

    const [value, setValue] = useState(() => {
        if (locationState?.navData?.dateRange) {
            return locationState.navData.dateRange;
        }

        return { startDate: null, endDate: null };
    });

    const handleDateRangeChange = (value: { startDate: Date | null, endDate: Date | null }) => {
        setValue(value);

        const startDate = value.startDate ? new Date(value.startDate).toISOString().split('T')[0] : '';
        const endDate = value.endDate ? new Date(value.endDate).toISOString().split('T')[0] : '';

        updateFilter('startdate', startDate);
        updateFilter('enddate', endDate);
    };

    const handlePageChange = (newPage: number) => updateFilter('page', newPage);
    const handleRowClick = (row: any) => {
        onNavigateToTaskReport({
            ...row,
            navData: {
                tabIndex: 2,
                dateRange: value,
                filters: {
                    sort: queryFilters.sort,
                    filter: queryFilters.filter,
                    kind: queryFilters.kind,
                    team: queryFilters.team,
                    user: queryFilters.user,
                    task: queryFilters.task,
                    category: queryFilters.category,
                    search: queryFilters.search,
                    startdate: queryFilters.startdate,
                    enddate: queryFilters.enddate,
                    page: queryFilters.page
                }
            }
        });
    };

    const updateFilter = (key: string, value: any) => {
        setQueryFilters((prevState: any) => ({
            ...prevState,
            [key]: value,
            ...(key !== 'page' ? { page: 0 } : {})
        }));
    };

    const hasQueryFiltersChanged = (currentFilters: QueryFilters): boolean => {
        const normalizeValue = (value: string | number | null | undefined) => {
            // Convert empty strings, 0, and null to consistent values
            if (value === '' || value === 0 || value === null || value === undefined) {
                return '';
            }

            // Convert all other values to strings for consistent comparison
            return String(value);
        };

        const meaningfulFilters = {
            filter: normalizeValue(currentFilters.filter === 'all' ? '' : currentFilters.filter),
            kind: normalizeValue(currentFilters.kind === 'all' ? '' : currentFilters.kind),
            team: normalizeValue(currentFilters.team),
            user: normalizeValue(currentFilters.user),
            task: normalizeValue(currentFilters.task),
            category: normalizeValue(currentFilters.category),
            search: normalizeValue(currentFilters.search),
            startdate: normalizeValue(currentFilters.startdate),
            enddate: normalizeValue(currentFilters.enddate)
        };

        const initialMeaningful = {
            filter: '',
            kind: '',
            team: '',
            user: '',
            task: '',
            category: '',
            search: '',
            startdate: '',
            enddate: ''
        };

        return JSON.stringify(meaningfulFilters) !== JSON.stringify(initialMeaningful);
    };

    const resetFilters = () => {
        setQueryFilters(INITIAL_QUERY_FILTERS);
        setValue({ startDate: null, endDate: null });
        setIsReset(true);
    };

    const getTeamsAndUsers = async () => {
        try {
            const teamsWithUsers: { teamid: number, users: any }[] = [];
            const allUsers: any[] = [];

            const teams = await getMyTeams();

            for (const team of teams?.manages) {
                const members = await getTeamMembers(team.teamid);

                allUsers.push(...members?.members);

                teamsWithUsers.push({
                    teamid: team.teamid,
                    users: members?.members
                });
            }

            setAllUsers(allUsers);
            setTeamsWithUsers(teamsWithUsers);
            setAvailableUsers(allUsers);
            setTeamsManaged(teams?.manages);
        } catch (error: any) {
            console.error(error);
            toast({
                title: 'Error fetching teams and users',
                description: error?.message,
                status: 'error',
                duration: 5000,
                isClosable: true
            });
        }
    };

    useEffect(() => {
        getTeamsAndUsers();

        return () => {
            setQueryFilters(INITIAL_QUERY_FILTERS);
            setValue({ startDate: null, endDate: null });
        };
    }, []);

    // Restore page index from navigation if available
    useEffect(() => {
        if (locationState?.savedPageIndex !== undefined) {
            setQueryFilters((prev: any) => ({
                ...prev,
                page: locationState.savedPageIndex
            }));
        }
    }, [locationState?.savedPageIndex]);

    useEffect(() => {
        console.log('teamsWithUsers updated:', teamsWithUsers);
    }, [teamsWithUsers]);

    useEffect(() => {
        console.log('availableUsers updated:', availableUsers);
    }, [availableUsers]);

    useEffect(() => {
        setLoading(true);

        getAllTaskReports(
            queryFilters.sort,
            queryFilters.filter,
            queryFilters.kind,
            queryFilters.team,
            queryFilters.user,
            queryFilters.task,
            queryFilters.category,
            queryFilters.search,
            queryFilters.startdate,
            queryFilters.enddate,
            (queryFilters.page + 1),
            10
        )
            .then((res: any) => {
                setTaskReports(res.reports);
                setReportCount(res.reportCount);
                setCategories(res.categories);
                setPageCount(res.pageCount);

                // Calculate pass percentage based on current filter
                if (res.reportCount > 0) {
                    if (queryFilters.filter === 'actions') {
                        // Count how many of the action reports are passed
                        const passedCount = res.reports.filter((report: any) => report.reportstatus === '1').length;
                        const percentage = (passedCount / res.reports.length) * 100;
                        setPercPassed(Math.min(100, Math.max(0, percentage)).toFixed(0));
                    } else if (queryFilters.filter === 'all' || queryFilters.filter === 'passed') {
                        // Original logic for all/passed filters
                        getAllTaskReports(
                            queryFilters.sort,
                            'passed',
                            queryFilters.kind,
                            queryFilters.team,
                            queryFilters.user,
                            queryFilters.task,
                            queryFilters.category,
                            queryFilters.search,
                            queryFilters.startdate,
                            queryFilters.enddate,
                            1,
                            10
                        ).then((passedRes: any) => {
                            const percentage = (passedRes.reportCount / res.reportCount) * 100;
                            setPercPassed(Math.min(100, Math.max(0, percentage)).toFixed(0));
                        });
                    } else {
                        setPercPassed('0');
                    }
                } else {
                    setPercPassed('0');
                }
            })
            .catch((error: any) => {
                console.error('Error fetching task reports:', error);
                toast({
                    title: 'Error fetching task reports',
                    description: error?.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [queryFilters]);

    return (
        <>
            <VStack alignItems='alignItems'>
                <HStack spacing={4} alignItems='end' pb={isMobile ? 4 : 2}>
                    <SmallFilterCard
                        title='Passed'
                        bgColor='gray.50'
                        icon={BsCheckCircleFill}
                        iconColor='green.500'
                        selectedCard={capitalizeFirstChar(queryFilters.filter)}
                        onClickAction={() => {
                            updateFilter('filter', queryFilters.filter !== 'passed' ? 'passed' : 'all');
                            updateFilter('kind', 'all');
                        }}
                    />
                    <SmallFilterCard
                        title='Failed'
                        bgColor='gray.50'
                        icon={BsXCircleFill}
                        iconColor='red.500'
                        selectedCard={capitalizeFirstChar(queryFilters.filter)}
                        onClickAction={() => {
                            updateFilter('filter', queryFilters.filter !== 'failed' ? 'failed' : 'all');
                            updateFilter('kind', 'all');
                        }}
                    />
                    <SmallFilterCard
                        title='Missed'
                        bgColor='gray.50'
                        icon={BsClockFill}
                        iconColor='yellow.500'
                        selectedCard={capitalizeFirstChar(queryFilters.filter)}
                        onClickAction={() => {
                            updateFilter('filter', queryFilters.filter !== 'missed' ? 'missed' : 'all');
                            updateFilter('kind', 'all');
                        }}
                    />
                    {
                        !isMobile && <>
                            <SmallFilterCard
                                title='Observed'
                                bgColor='gray.50'
                                icon={BsEyeFill}
                                iconColor='gray.500'
                                selectedCard={queryFilters.kind === 'guided' ? 'Observed' : 'all'}
                                onClickAction={() => {
                                    updateFilter('kind', queryFilters.kind !== 'guided' ? 'guided' : 'all');
                                    updateFilter('filter', 'all');
                                }}
                            />
                            <SmallFilterCard
                                title='Actions'
                                bgColor='gray.50'
                                icon={BsWrench}
                                iconColor='gray.500'
                                selectedCard={queryFilters.filter === 'actions' ? 'Corrective Actions' : 'all'}
                                onClickAction={() => {
                                    updateFilter('filter', queryFilters.filter !== 'actions' ? 'actions' : 'all');
                                    updateFilter('kind', 'all');
                                }}
                            />
                        </>
                    }
                    {
                        !isMobile &&
                        hasQueryFiltersChanged(queryFilters) && <Button
                            colorScheme='red'
                            variant='link'
                            size='sm'
                            pl={3}
                            _hover={{ bg: '' }}
                            _active={{ bg: '' }}
                            onClick={resetFilters}
                        >
                            <HStack color='red.500'>
                                <Icon as={BsTrash} fontSize='md' />
                                <Text>Clear Search</Text>
                            </HStack>
                        </Button>
                    }
                    <Spacer />
                    {
                        !isMobile && <Box border='1px solid' borderColor='gray.200' p={4} rounded='lg'>
                            <HStack>
                                <VStack alignItems="start">
                                    <HStack spacing={2}>
                                        <Text fontSize={isLg ? 'sm' : 'lg'} fontWeight={500}>
                                            Task Reports Found
                                        </Text>
                                        <Badge colorScheme='facebook'>{loading ? <Spinner size='xs' /> : reportCount}</Badge>
                                    </HStack>
                                    <HStack spacing={2}>
                                        <Text fontSize='sm' fontWeight={500}>
                                            Passed
                                        </Text>
                                        <Badge colorScheme={percPassed < 50 ? 'red' : percPassed < 70 ? 'orange' : 'green'}>
                                            {loading ? <Spinner size='xs' mr={1} /> : percPassed}%
                                        </Badge>
                                    </HStack>
                                </VStack>
                                {
                                    !isLg && <>
                                        <Spacer />

                                        <Image
                                            src={upload}
                                            alignSelf='end'
                                            pl={3}
                                            h='60px'
                                        />
                                    </>
                                }
                            </HStack>
                        </Box>
                    }
                </HStack>
                {
                    !isMobile &&
                    <Accordion
                        defaultIndex={hasQueryFiltersChanged(queryFilters) ? [0] : undefined}
                        allowToggle
                    >
                        <AccordionItem
                            borderBottom='none'
                            mb={0}
                            sx={{
                                overflow: 'visible !important',
                                '& > *': {
                                    overflow: 'visible !important'
                                }
                            }}
                        >
                            <AccordionButton
                                py={4}
                                px={2}
                                _hover={{ bg: '' }}
                                _focus={{ boxShadow: 'none' }}
                            >
                                <Icon
                                    as={BsSearch}
                                    color='gray.600'
                                    mr={3}
                                />
                                <Text
                                    color='gray.600'
                                    fontSize='sm'
                                    fontWeight={500}
                                    mr={4}
                                >
                                    Advanced Search
                                </Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel
                                p={0}
                                mt={0}
                                sx={{
                                    overflow: 'visible !important',
                                    '& > *': {
                                        overflow: 'visible !important'
                                    }
                                }}
                            >
                                <Stack
                                    direction={{ base: 'column', md: 'row' }}
                                    spacing={isMobile ? 2 : 4}
                                    mb={2}
                                    w='100%'
                                >
                                    <Box flex='1' position='relative'>
                                        <CategoryCombobox
                                            categories={categories}
                                            selectedCategory={queryFilters.category}
                                            update={(category: any) => {
                                                updateFilter('category', category);
                                            }}
                                        />
                                    </Box>
                                    <Box flex='2' position='relative'>
                                        <TeamUserSelection
                                            teams={teamsManaged}
                                            allUsers={allUsers}
                                            teamsWithUsers={teamsWithUsers}
                                            isReset={isReset}
                                            onResetComplete={() => setIsReset(false)}
                                            selectedTeamId={queryFilters.team}
                                            selectedUserId={queryFilters.user}
                                            onFiltersChange={(teamId, userId): void => {
                                                updateFilter('team', teamId);
                                                updateFilter('user', userId);
                                            }}
                                        />
                                    </Box>
                                </Stack>
                                <HStack
                                    spacing={isMobile ? 2 : 4}
                                    mb={4}
                                    sx={{
                                        overflow: 'visible !important'
                                    }}
                                >
                                    <Box
                                        w='100%'
                                        position='relative'
                                        className='!overflow-visible'
                                    >
                                        <Datepicker
                                            displayFormat='DD/MM/YYYY'
                                            placeholder='Search Dates'
                                            separator='-'
                                            primaryColor='emerald'
                                            showShortcuts={true}
                                            useRange={true}
                                            value={value}
                                            onChange={(value) => handleDateRangeChange(value as { startDate: Date | null, endDate: Date | null })}
                                            popoverDirection='down'
                                            inputClassName='w-full h-[40px] bg-white border border-gray-200 pl-4 py-2 rounded-lg cursor-pointer focus:outline-none focus:ring-0'
                                            toggleClassName='absolute bg-gray-500 rounded-r-lg text-white right-0 h-full px-3 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed'
                                        />
                                    </Box>
                                    <Input
                                        placeholder='Search Tasks'
                                        value={queryFilters.search}
                                        onChange={(e) => updateFilter('search', e.target.value)}
                                    />
                                </HStack>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                }
            </VStack>
            {
                loading ? <Spinner /> : (taskReports.length === 0 ?
                    <Center mt={8}>
                        <VStack>
                            <Image src={nothingToSee} h='100px' />
                            <Text fontWeight={500}>
                                No Task Reports Found
                            </Text>
                        </VStack>
                    </Center> :

                    <ObbiTable
                        columns={columns}
                        data={taskReports}
                        initialPage={queryFilters.page}
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        onRowClick={handleRowClick}
                        sortColumn={queryFilters.sort}
                        onSort={(sortKey) => updateFilter('sort', sortKey)}
                        getRowProps={(row) => ({
                            bgColor: row.original.isfailed
                                ? 'red.50' : row.original.ismissed ? 'orange.50' : 'transparent',
                            borderBottom: row.original.isfailed
                                ? '1px solid #FED7D7' : row.original.ismissed ? '1px solid #FEF3C7' : '1px solid #EDF2F7'
                        })}
                    />
                )
            }
        </>
    );
};

export default TaskReporting;