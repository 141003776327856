import { Box, Center, HStack, Icon, SlideFade, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import { useMemo } from "react";
import { BsArrowUp, BsArrowDown, BsExclamationCircle } from "react-icons/bs";
import { useTable, useSortBy, usePagination } from "react-table";
import { Pager } from "../common/Pager";

export default function RATemplateTable({
    data,
    loading,
    pageCount: controlledPageCount,
    onHeaderClick,
    rowClickHandler,
    sort,
    setPageIndex,
    pageIndex,
    pageCount

}: any) {

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "raid",
                disableSortBy: true,
            },
            {
                Header: <>
                    TITLE {
                        sort === "raname" ?
                            <Icon as={BsArrowUp} color="gray.600" /> :
                            sort === "ranamedesc" ?
                                <Icon as={BsArrowDown} color="gray.600" /> :
                                <></>
                    }
                </>,
                accessor: "raname",
                Cell: (cell: any) => {
                    return <Box
                        display={'flex'}
                        flexWrap={'wrap'}
                        maxWidth={'500px'}
                        width="100%"
                    >
                        <VStack
                            alignItems={'flex-start'}
                        >

                            <Text
                                color="gray.600"
                                fontSize="sm"
                                whiteSpace="normal"
                                overflow="visible"
                                textOverflow="clip"
                                minWidth="120px"
                            >
                                {cell.value || "Untitled"}
                            </Text>
                            {cell.row.original?.radescription &&
                                <Text
                                    color="gray.600"
                                    fontSize="xs"
                                    whiteSpace="normal"
                                    overflow="visible"
                                    textOverflow="clip"
                                    minWidth="120px"
                                >
                                    {cell.row.original?.radescription}
                                </Text>
                            }
                        </VStack>
                    </Box>

                }
            },
            {
                Header: <>
                    CATEGORY {
                        sort === "racategory" ?
                            <Icon as={BsArrowUp} color="gray.600" /> :
                            sort === "racategorydesc" ?
                                <Icon as={BsArrowDown} color="gray.600" /> :
                                <></>
                    }
                </>,
                accessor: "racategory",
                Cell: (cell: any) => <Box
                    display={'flex'}
                    flexWrap={'wrap'}
                    maxWidth={'400px'}
                    width="100%"
                >
                    <Text
                        color="gray.600"
                        fontSize="sm"
                        whiteSpace="normal"
                        overflow="visible"
                        textOverflow="clip"
                        minWidth="100px"
                    >
                        {cell.value || "Untitled"}
                    </Text>
                </Box>

            },
        ], [sort]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            pageCount: controlledPageCount,
            onHeaderClick,
            autoResetPage: false,
            initialState: {
                hiddenColumns: ["raid"],
            }
        },
        useSortBy,
        usePagination
    );


    return (
        <SlideFade in={!loading}>

            {/* mobile view  */}
            <VStack
                display={{ base: "flex", lg: "none" }}
                mb={data.length > 6 ? 5 : 0}
            >
                {
                    data.map((dataItem: any, i: number) => (
                        <Box key={i} w="100%">
                            <Box
                                p={3}
                                w="full"
                                className="hover-pop"
                                cursor="pointer"
                                boxShadow="xs"
                                rounded="lg"
                                border="1px solid"
                                borderColor="gray.100"
                                onClick={() => {
                                    rowClickHandler(dataItem);
                                }}
                            >
                                <VStack alignItems="start">
                                    <Text
                                        color="gray.600"
                                        fontSize="sm"
                                        whiteSpace="normal"
                                        overflow="visible"
                                        textOverflow="clip"
                                        minWidth="120px"
                                        fontWeight={600}
                                    >
                                        {dataItem.raname || "Untitled"}
                                    </Text>

                                    {dataItem?.radescription &&
                                        <Text
                                            color="gray.600"
                                            fontSize="xs"
                                            whiteSpace="normal"
                                            overflow="visible"
                                            textOverflow="clip"
                                            minWidth="120px"
                                        >
                                            {dataItem?.radescription}
                                        </Text>
                                    }

                                    {dataItem.racategory &&
                                        <HStack>
                                            <Icon
                                                as={BsExclamationCircle}
                                                fontWeight={600}
                                                color="green.500"
                                            />
                                            <Text
                                                fontSize="xs"
                                                fontWeight={500}
                                                textAlign="start"
                                                color="gray.500"
                                            >
                                                {dataItem.racategory}
                                            </Text>
                                        </HStack>
                                    }
                                </VStack>
                            </Box>
                        </Box>
                    ))
                }
            </VStack>

            {/* desktop view */}
            <Table
                {...getTableProps()}
                id="RiskAssessmentTable"
                variant="striped"
                display={{ base: "none", lg: "inline-table" }}
            >
                <Thead>
                    {
                        page.length != 0 && headerGroups.map((headerGroup, i) => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map((column, i) => (
                                        <Th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            onClick={() => onHeaderClick(column)}
                                            key={`task_header_${i}`}
                                        >
                                            <Text fontWeight={700} color="gray.600">
                                                {column.render("Header")}
                                            </Text>
                                        </Th>
                                    ))
                                }
                            </Tr>
                        ))
                    }
                </Thead>

                <Tbody {...getTableBodyProps()}>
                    {
                        page.length === 0 ?
                            <Center m={5}>
                                <VStack>
                                    <Text fontSize="xl" fontWeight={600}>
                                        No Risk Assessments Found
                                    </Text>
                                    <Text fontWeight={400} color="gray.500">
                                        You have no risk assessments available
                                    </Text>
                                </VStack>
                            </Center>
                            :
                            page.map((row, i) => {
                                prepareRow(row);

                                return (
                                    <Tr
                                        {...row.getRowProps()}
                                        key={i}
                                        bg={i % 2 ? "white" : "purple.50"}
                                        borderBottom="1px solid"
                                        borderColor="gray.100"
                                        cursor="pointer"
                                        rounded="lg"
                                        onClick={() => {
                                            rowClickHandler(row.original);
                                        }}
                                    >
                                        {
                                            row.cells.map((cell) => (
                                                <Td wordBreak="break-word" {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </Td>
                                            ))
                                        }
                                    </Tr>
                                );
                            })
                    }
                </Tbody>
            </Table>
            {
                pageCount > 1 && <Box
                    mt={3}
                    textAlign="center"
                >
                    <Pager
                        pageCount={pageCount || 0}
                        pageIndex={pageIndex || 0}
                        setPageIndex={setPageIndex}
                    />
                </Box>
            }
        </SlideFade>
    );
}