import React, { useEffect, useState } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { BsArrowLeftCircle, BsGraphUp } from 'react-icons/bs';

// Components
import ObbiContainer from '../../common/ObbiContainer';

// Types
import { TaskReport } from './types/TaskReportingTypes';

// API
import BackButton from '../../common/BackButton';

const TaskReporting: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [report, setReport] = useState<TaskReport[]>([]);

    useEffect(() => {
        // setLoading(true);

        // getTaskReport()
        //     .then((res: any) => {
        //         setTaskReport(res);
        //     })
        //     .catch((error: any) => {
        //         console.error('Error fetching task report:', error);
        //     })
        //     .finally(() => {
        //         setLoading(false);
        //     });
    }, []);

    return (
        <ObbiContainer
            icon={BsGraphUp}
            title="Task Report"
            backLink={
                <BackButton
                    icon={BsArrowLeftCircle}
                    text="Back to All Reports"
                    to="/tasks"
                    navigateState={{ tabIndex: 2 }}
                />
            }
        >
            {
                loading ? <Spinner /> : <Box>{report}</Box>
            }
        </ObbiContainer>
    );
};

export default TaskReporting;