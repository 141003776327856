import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconType } from 'react-icons';
import { Button, Icon, ButtonProps } from '@chakra-ui/react';

interface BackButtonProps extends ButtonProps {
    text: string;
    to: string;
    icon?: IconType;
    navigateState?: any;
}

const BackButton: React.FC<BackButtonProps> = ({ text, to, icon, navigateState, ...buttonProps }) => {
    const navigate = useNavigate();

    return (
        <Button
            bg="white"
            color="gray.700"
            size="sm"
            shadow={{ base: "none", md: "md" }}
            leftIcon={icon && <Icon as={icon} />}
            onClick={() => navigate(to, { state: navigateState })}
            {...buttonProps}
        >
            {text}
        </Button>
    );
};

export default BackButton;