import React from 'react';
import GenericCombobox from './GenericCombobox';

interface CategoryComboboxProps {
    categories: any[];
    selectedCategory: any;
    update: (id: string) => void;
    disabled?: boolean;
};

const CategoryCombobox: React.FC<CategoryComboboxProps> = ({
    categories,
    selectedCategory,
    update,
    disabled
}) => {
    const getDisplayValue = (category: any) => {
        if (typeof category === 'string') return category;
        if (!category || category.taskcategory === "") return "All Categories";

        return category.taskcategory;
    };

    const addDefaultCategory = (categories: any[]) => [
        { taskcategory: "", id: 0 },
        ...categories
    ];

    const filterFunction = (category: any, query: string) => {
        if (!category.taskcategory) return false;

        return category.taskcategory.toLowerCase().includes(query.toLowerCase());
    };

    const handleUpdate = (category: any) => {
        update(category?.taskcategory || "");
    };

    return (
        <GenericCombobox
            data={addDefaultCategory(categories)}
            selectedItem={selectedCategory || 0}
            update={handleUpdate}
            disabled={disabled}
            placeholder="All Categories"
            displayValue={getDisplayValue}
            filterFunction={filterFunction}
        />
    );
};

export default CategoryCombobox;