import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import {
	Avatar,
	Box,
	Button,
	Flex,
	HStack,
	Icon,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	SlideFade,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue,
	VStack,
} from "@chakra-ui/react";
import { BsArrowRepeat, BsBellFill, BsBoxArrowRight, BsFileEarmarkText, BsPersonCircle, BsQuestionCircle } from "react-icons/bs";

import store from "../../redux/store";
import { RootState } from "../../redux/rootReducer";

import { getBreakdown } from "../../features/notifications/notification.actions";
import { getMessageFolders } from "../../features/messages/message.actions";
import { setProductTier, setRiskAssessmentsEnabled, updatePasswordExpiryStatus, verifyEmail, verifyEmailExists, verifyTerms, setLibraryOrg } from "../../features/auth/auth.slice";
import { destroySession, getClickthroughURL, getCrsfToken, selectClassicObbi, validate } from "../../features/auth/auth.actions";

import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";
import { Banner } from "./Banner";
import { CsrfBanner } from "./CsrfBanner";

export const Shell = () => {
	const org = useSelector((state: RootState) => state.authReducer);
	const user = useSelector((state: RootState) => state.authReducer.user);

	const navigateTo = useNavigate();
	const isDesktop = useBreakpointValue({ base: false, lg: true });
	const location = useLocation();

	const [firstTimeLoading, setFirstTimeLoading] = useState(true);
	const [loggingOut, setLoggingOut] = useState(false);

	const [showCrsfBanner, setShowCrsfBanner] = useState(false);
	const showBanner = !location.pathname.includes("home") &&
		!location.pathname.includes("admin") &&
		!location.pathname.includes("messages");

	const messageFolders = useSelector(
		(state: RootState) => state.messageReducer.messageFolders
	);

	const notificationFolders = useSelector(
		(state: RootState) => state.notificationReducer.notificationFolders
	);

	const handleClickThrough = async (path: string) => {
		let url: string = await getClickthroughURL(path);
		window.open(url, "_self");
	};

	const handleLogout = async () => {
		setLoggingOut(true);

		await destroySession()
			.finally(() => {
				localStorage.clear();
				window.location.href = `${process.env.REACT_APP_ENV_URL}/login`;
			});
	};

	const verifyUserData = (userData: any) => {
		if (!user.emailVerified && userData.emailVerified) {
			store.dispatch(verifyEmail());
		}

		if (!user.termsAccepted && userData.termsAccepted) {
			store.dispatch(verifyTerms());
		}

		if (isEmpty(user.email) && !isEmpty(userData.email)) {
			store.dispatch(verifyEmailExists(userData.email));
		}

		if (org.orglibraryorganisation != userData.orglibraryorganisation) {
			store.dispatch(setLibraryOrg(userData.orglibraryorganisation));
		}

		store.dispatch(updatePasswordExpiryStatus({
			passwordAboutToExpire: userData.passwordAboutToExpire,
			daysUntilPasswordExpires: userData.daysUntilPasswordExpires
		}));
	};

	async function onFocus() {
		const v: any = await validate();

		if (v.status === 200 && v.data.error === 0) {
			verifyUserData(v.data);

			if (v.data.userid !== user.id) {
				handleLogout();
			}

			if (!v.data.seasurf) {
				setShowCrsfBanner(true);
			}
		}

		if (v.status === 401) {
			handleLogout();
		}

		if (isEmpty(user?.producttier)) {
			console.log('Product tier is empty - updating')
			store.dispatch(setProductTier(v?.data?.producttier));

		} else if (!isEmpty(v?.data?.producttier) && (JSON.stringify(v?.data?.producttier) !== JSON.stringify(user?.producttier))) {
			console.log('Product tier has changed - updating')
			store.dispatch(setProductTier(v?.data?.producttier));
		}

		if (v?.data?.orgriskassessmentsenabled !== org?.orgriskassessmentsenabled) {
			console.log('Risk assessments enabled has changed - updating')
			store.dispatch(setRiskAssessmentsEnabled(v?.data?.orgriskassessmentsenabled));
		}
	};

	const reauth = () => {
		getCrsfToken()
			.then(() => {
				setShowCrsfBanner(false);
				window.location.reload();
			})
			.catch((error: any) => {
				console.log(error);
			});
	};

	useEffect(() => {
		const isSuperAdmin = user.orgid === 0 && user.isAdmin;

		if (isSuperAdmin) {
			handleClickThrough("Admin");
			return;
		}

		window.scrollTo({ top: 0 });
		window.addEventListener("focus", onFocus);

		getMessageFolders()
			.finally(() => { setFirstTimeLoading(false) });

		getBreakdown();

		return () => window.removeEventListener("focus", onFocus);
	});

	return (
		<Flex
			as="section"
			direction={{ base: "column", lg: "row" }}
		>
			{
				isDesktop ? <Sidebar messageCount={messageFolders.unreadCount} isAdmin={user.isAdmin} /> :
					<Navbar isAdmin={user.isAdmin} loading={firstTimeLoading} />
			}

			<Box bg={{ base: "white", md: "bg-surface" }} flex="1">
				<Flex
					height="50px"
					bg={{ base: "white", md: "bg-surface" }}
					justify="space-between"
					py={{ base: "3", lg: "4" }}
					display={{ base: "none", lg: "flex" }}
				>
					<HStack spacing={"10"} />

					<HStack mr="20px" gap={1}>
						{
							org.orgnewobbi === 1 && <Button
								size="sm"
								variant="outline"
								_hover={{ bg: "" }}
								onClick={() => {
									selectClassicObbi()
										.then(() => {
											handleClickThrough("Home");
										})
								}}
							>
								<Icon as={BsArrowRepeat} mr={2} /> Switch to Classic Obbi
							</Button>
						}

						<Button
							variant="outline"
							size="sm"
							_hover={{ bg: "" }}
							onClick={() => {
								window.open('https://help.obbisolutions.com/', '_blank');
							}}
						>
							<HStack>
								<Icon as={BsQuestionCircle} />
								<Text>Help</Text>
							</HStack>
						</Button>

						<Button
							size="sm"
							variant="outline"
							_hover={{ bg: "" }}
							color={
								firstTimeLoading ? "gray.600" :
									Number(notificationFolders.totalCount) > 0 ? "red.500" : "gray.600"
							}
							borderColor={
								firstTimeLoading ? "gray.200" :
									Number(notificationFolders.totalCount) > 0 ? "red.100" : "gray.200"
							}
							onClick={() => {
								navigateTo("/notifications",
									{
										state: {
											tabIndex: 0
										}
									}
								);
							}}
						>
							<Icon
								as={BsBellFill}
								mr={Number(notificationFolders.totalCount) > 0 ? 1 : 0}
								color={
									firstTimeLoading ? "gray.600" :
										Number(notificationFolders.totalCount) > 0 ? "red.500" : "gray.600"
								}
							/>

							{
								Number(notificationFolders.totalCount) > 0 && notificationFolders.totalCount
							}
						</Button>

						<Flex alignItems={"center"}>
							<Menu>
								<MenuButton
									py={2}
									transition="all 0.3s"
									_focus={{ boxShadow: "none" }}
								>
									<HStack>
										<VStack
											display={{ base: "flex" }}
											alignItems="flex-start"
											spacing="1px"
										>
											<Avatar
												bg="brand.500"
												color="white"
												name={`${user.forename} ${user.surname}`}
												size="sm"
											/>
										</VStack>
									</HStack>
								</MenuButton>

								<MenuList
									bg={useColorModeValue("white", "gray.900")}
									borderColor={useColorModeValue("gray.200", "gray.700")}
								>
									<MenuItem onClick={() => navigateTo("profile")}>
										<HStack color="gray.600">
											<Icon as={BsPersonCircle} />
											<Text>My Profile</Text>
										</HStack>
									</MenuItem>

									<MenuItem>
										<HStack color="gray.600">
											<Icon as={BsFileEarmarkText} />
											<a href="https://help.obbisolutions.com/en_US/release-notes" target="_blank">Release Notes</a>
										</HStack>
									</MenuItem>

									<MenuDivider />

									{
										org.orgnewobbi === 1 && <MenuItem
											onClick={() => {
												selectClassicObbi()
													.then(() => {
														handleClickThrough("Home");
													})
											}}
										>
											<HStack color="gray.600">
												<Icon as={BsArrowRepeat} />
												<Text>Switch to Classic Obbi</Text>
											</HStack>
										</MenuItem>
									}

									<MenuItem onClick={() => handleLogout()}>
										<HStack color="gray.600">
											<Icon as={BsBoxArrowRight} />
											<Text>Sign Out</Text>
										</HStack>
									</MenuItem>
								</MenuList>
							</Menu>
						</Flex>
					</HStack>
				</Flex>

				<Box
					borderTopLeftRadius={{ base: "none", lg: "2rem" }}
					bg={{ base: "white", md: "gray.100" }}
					mt={{ base: "65px", lg: 0 }}
					ml={{ base: 0, lg: "235px" }}
				>
					<Stack>
						<SlideFade in={true}>
							{
								!loggingOut && showBanner &&
								messageFolders.unreadCriticalCount > 0 && <Banner
									showBannerLink={true}
									unreadCriticalCount={messageFolders.unreadCriticalCount}
								/>
							}

							{
								!loggingOut && showCrsfBanner && <CsrfBanner reauth={reauth} />
							}

							<Outlet />

						</SlideFade>
					</Stack>
				</Box>
			</Box>
		</Flex>
	);
};