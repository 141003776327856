import React from 'react';
import GenericCombobox from './GenericCombobox';
import { Team, TeamComboboxProps } from './types/TaskReportingTypes';

const TeamCombobox: React.FC<TeamComboboxProps> = ({
    teams,
    selectedTeam,
    update,
    disabled
}) => {
    const getId = (team: Team): number => {
        return team?.teamid;
    };

    const getDisplayValue = (team: Team): string => {
        if (!team) return "All Teams";
        return team.teamname || "All Teams";
    };

    const addDefaultTeam = (teams: Team[]): Team[] => [
        { teamid: 0, teamname: "All Teams" },
        ...teams
    ];

    const filterFunction = (team: Team, query: string): boolean =>
        team.teamname?.toLowerCase()?.includes(query.toLowerCase());

    // Transform selectedTeam to the correct format
    const transformedSelected = typeof selectedTeam === 'number'
        ? { teamid: selectedTeam, teamname: "" }
        : selectedTeam;

    return (
        <GenericCombobox<Team>
            data={addDefaultTeam(teams)}
            selectedItem={transformedSelected}
            update={update}
            disabled={disabled}
            placeholder="All Teams"
            getId={getId}
            displayValue={getDisplayValue}
            filterFunction={filterFunction}
        />
    );
};

export default TeamCombobox;