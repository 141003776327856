import {
    Badge,
    Box,
    Center,
    Divider,
    FormControl,
    Grid,
    HStack,
    Icon,
    Select,
    SlideFade,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { BsArrowDown, BsArrowUp, BsBell, BsExclamationCircleFill } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { getNotifications } from "../../features/notifications/notification.actions";
import { InsightActionCard } from "../common/InsightActionCard";

import incident from "../../assets/images/incident.svg";
import whiteboard from "../../assets/images/whiteboard.svg";
import board from "../../assets/images/org-board.svg";

import { useTable, useSortBy, usePagination } from "react-table";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { Pager } from "../common/Pager";
import { SmallFilterCard } from '../common/SmallFilterCard';
import { localDateTime } from '../../helpers/DayJsHelper';

export const NotificationTable: React.FC<any> = ({ tabIndex, grouping, setGrouping, index, setPageIndex }) => {
    const toast = useToast();
    const navigateTo = useNavigate();
    const notificationFolders = useSelector((state: RootState) => state.notificationReducer.notificationFolders);

    const [firstTimeLoading, setFirstTimeLoading] = useState(true);
    const [loading, setLoading] = useState<boolean>(false);

    const [categories, setCategories] = useState([]);

    const [category, setCategory] = useState<string>("");
    const [kind, setKind] = useState(0);
    const setCategoryData = (categoryData: any) => {
        setCategory(categoryData.category);
        setKind(categoryData.kind);
        setPageIndex(0);
    };

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [sort, setSort] = useState<string>("datesentdesc");

    const columns = useMemo(
        () => [
            {
                Header: "",
                accessor: "id",
                disableSortBy: true
            },
            {
                Header: <>
                    NOTIFICATION {
                        sort === "text" ?
                            <Icon as={BsArrowUp} color="gray.600" /> :
                            sort === "textdesc" ?
                                <Icon as={BsArrowDown} color="gray.600" /> :
                                <></>
                    }
                </>,
                accessor: "title",
                Cell: (cell: any) => <HStack>
                    {
                        cell.value.critical &&
                        <Icon
                            mr={2}
                            color="red.500"
                            justifySelf="center"
                            as={BsExclamationCircleFill}
                        />
                    }
                    <Text color="gray.600">{cell.value.title || "Untitled"}</Text>
                </HStack>
            },
            {
                Header: <>
                    RECEIVED {
                        sort === "datecreated" ?
                            <Icon as={BsArrowUp} color="gray.600" /> :
                            sort === "datecreateddesc" ?
                                <Icon as={BsArrowDown} color="gray.600" /> :
                                <></>
                    }
                </>,
                accessor: "received",
                Cell: (cell: any) => <Text color="gray.600">
                    {localDateTime(cell.value)}
                </Text>
            }
        ], [sort]
    );

    const sortColumn = (term: string) => {
        switch (term) {
            case "title":
                if (sort === "text") {
                    setSort("textdesc");
                } else if (sort === "textdesc") {
                    setSort("text");
                } else setSort("textdesc");
                break;
            case "received":
                if (sort === "datecreated") {
                    setSort("datecreateddesc");
                } else if (sort === "datecreateddesc") {
                    setSort("datecreated");
                } else setSort("datecreateddesc");
                break;
        }
    };

    const fetchData = useCallback(({
        sort,
        tabIndex,
        kind,
        grouping,
        index
    }) => {
        setLoading(true);
        index = (index + 1).toString();

        let filter = "new";
        switch (tabIndex) {
            case 1: filter = "all";
                break;
            case 2: filter = "cleared";
                break;
        }

        getNotifications(
            kind,
            filter,
            grouping,
            sort,
            index
        )
            .then((result: any) => {
                const formattedCurrentItems = result.notifications.map((notification: any) => {
                    return {
                        "id": notification.aeid,
                        "title": { critical: Number(notification.aekind) === 3, title: notification.aetext },
                        "received": notification.aedatecreated
                    }
                });

                setCategories(result.categories);
                setCurrentItems(formattedCurrentItems);
                setPageCount(result.pageCount);
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                setFirstTimeLoading(false);
            })
    }, [toast]);

    useEffect(() => {
        fetchData({
            sort,
            tabIndex,
            kind,
            grouping,
            index
        });
    }, [fetchData, kind, sort, grouping, index, category]);

    function DataTable({
        data,
        columns,
        loading,
        pageCount: controlledPageCount,
        onHeaderClick
    }: any) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            pageCount
        } = useTable({
            columns,
            data,
            manualPagination: true,
            manualSortBy: true,
            pageCount: controlledPageCount,
            onHeaderClick,
            autoResetPage: false,
            initialState: {
                hiddenColumns: ["id"]
            }
        },
            useSortBy,
            usePagination
        );

        return (
            <Fragment>
                <Grid
                    display={tabIndex !== 0 ? "none" : "grid"}
                    templateColumns={{
                        base: "repeat(3, 1fr)",
                        md: "repeat(4, 1fr)"
                    }}
                    gap={5}
                    mb={5}
                >
                    <InsightActionCard
                        title="Critical"
                        count={notificationFolders.criticalNewCount || 0}
                        image={incident}
                        color="red.500"
                        active={grouping === "critical"}
                        onClickAction={() => {
                            if (grouping === "critical") {
                                setGrouping("all")
                                setPageIndex(0);
                            } else {
                                setGrouping("critical");
                                setPageIndex(0);
                            }
                        }}
                        loading={firstTimeLoading}
                    />

                    <InsightActionCard
                        title="My Issues"
                        count={notificationFolders.personalNewCount || 0}
                        image={whiteboard}
                        color="warning.500"
                        active={grouping === "personal"}
                        onClickAction={() => {
                            if (grouping === "personal") {
                                setGrouping("all");
                                setPageIndex(0);
                            } else {
                                setGrouping("personal");
                                setPageIndex(0);
                            }
                        }}
                        loading={firstTimeLoading}
                    />

                    <InsightActionCard
                        title="Team Issues"
                        count={notificationFolders.teamNewCount || 0}
                        image={board}
                        color="red.500"
                        active={grouping === "team"}
                        onClickAction={() => {
                            if (grouping === "team") {
                                setGrouping("all");
                                setPageIndex(0);
                            } else {
                                setGrouping("team");
                                setPageIndex(0);
                            }
                        }}
                        loading={firstTimeLoading}
                    />
                </Grid>

                {
                    tabIndex === 0 && <Divider
                        w="unset"
                        mb={5}
                        mx={{ base: -5, md: -10 }}
                    />
                }

                {
                    categories?.length > 0 && categories?.length < 7 &&
                    <Box
                        p={1.5}
                        mt={5}
                        mb={2}
                        bg="gray.50"
                        textAlign="left"
                        rounded="lg"
                        border="1px solid"
                        borderColor="gray.100"
                    >
                        <Text
                            fontWeight={500}
                            color="gray.500"
                            fontSize="sm"
                            ml={1}
                            mb={2}
                        >
                            Notification Categories
                        </Text>

                        <SlideFade in={!firstTimeLoading} offsetY="20px">
                            <HStack>
                                {
                                    categories?.map((c: any, i: Number) => {
                                        return (
                                            <SmallFilterCard
                                                key={i}
                                                title={c.description}
                                                onClickAction={() => {
                                                    c.description !== category
                                                        ? setCategoryData({ category: c.description, kind: c.kind })
                                                        : setCategoryData({ category: "", kind: 0 });
                                                }}
                                                selectedCard={category}
                                                icon={BsBell}
                                                iconColor="brand.500"
                                            />
                                        );
                                    })
                                }
                            </HStack>
                        </SlideFade>
                    </Box>
                }

                {
                    categories?.length > 7 &&
                    <FormControl colorScheme="green" w={{ base: "full", lg: "50%" }}>
                        <Select
                            placeholder="All Categories"
                            _focus={{ borderColor: "brand.500", borderWidth: "1px" }}
                            color="gray.600"
                            fontSize="sm"
                            onChange={(e) => {
                                setCategoryData({ category: "", kind: e.target.value });
                            }}
                        >
                            {
                                categories?.map((c: any, i) => {
                                    return c.count > 0 && c.kind !== 3 && <option key={i} value={c.kind}>
                                        {`(${c.count}) ${c.description}`}
                                    </option>;
                                })
                            }
                        </Select>
                    </FormControl>
                }

                <Box
                    p={3}
                    mt={5}
                    border="1px"
                    borderColor={firstTimeLoading ? "transparent" : "gray.200"}
                    rounded="lg"
                >
                    <SlideFade in={!loading} offsetY="20px">
                        <VStack display={{ base: "flex", lg: "none" }} mb={5}>
                            {
                                currentItems?.map((notification: any, i: number) => (
                                    <Box
                                        w="full"
                                        p={2}
                                        className="hover-pop"
                                        cursor="pointer"
                                        backgroundColor={i % 2 ? "white" : "gray.50"}
                                        border="1px solid"
                                        borderColor="gray.100"
                                        rounded="lg"
                                        boxShadow="xs"
                                        onClick={() => {
                                            navigateTo(`/notifications/notification/${notification.id}`,
                                                {
                                                    state: {
                                                        tabIndex: tabIndex,
                                                        index: index
                                                    }
                                                });
                                        }}
                                    >
                                        <VStack alignItems="start">
                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.500"
                                                textAlign="start"
                                            >
                                                {localDateTime(notification.recievd)}
                                            </Text>

                                            <Text
                                                fontSize="sm"
                                                fontWeight={500}
                                                color="gray.600"
                                                textAlign="start"
                                            >
                                                {notification.title.title || "no title"}
                                            </Text>

                                            {
                                                notification.title.critical &&
                                                <Box textAlign="left" mx={5}>
                                                    <Badge
                                                        colorScheme="danger"
                                                        backgroundColor="transparent"
                                                        size="sm"
                                                        variant="outline"


                                                    >
                                                        <Icon
                                                            as={BsExclamationCircleFill}
                                                            mt="-2px"
                                                            mr="2px"
                                                            verticalAlign="middle"
                                                        /> Critical
                                                    </Badge>
                                                </Box>
                                            }
                                        </VStack>
                                    </Box>
                                ))
                            }
                        </VStack>
                    </SlideFade>

                    {
                        currentItems?.length < 1 && !loading ?
                            <Fragment>
                                <Center m={5}>
                                    <VStack>
                                        <Text fontSize="xl" fontWeight={600}>
                                            No Notifications Found
                                        </Text>
                                    </VStack>
                                </Center>
                            </Fragment>
                            :

                            <SlideFade in={!loading} offsetY="20px">
                                <Table
                                    {...getTableProps()}
                                    id="NotificatoinTable"
                                    variant="striped"
                                    display={{ base: "none", lg: "inline-table" }}
                                >
                                    <Thead>
                                        {
                                            headerGroups.map((headerGroup, i) => (
                                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                                    {
                                                        headerGroup.headers.map((column, i) => (
                                                            <Th
                                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                onClick={() => onHeaderClick(column)}
                                                                key={`notification_header_${i}`}
                                                            >
                                                                <Text fontWeight={700} color="gray.600">{column.render("Header")}</Text>
                                                            </Th>
                                                        ))
                                                    }
                                                </Tr>
                                            ))
                                        }
                                    </Thead>

                                    <Tbody {...getTableBodyProps()}>
                                        {
                                            page.map((row, i) => {
                                                prepareRow(row);
                                                return (
                                                    <Fragment>
                                                        <Tr
                                                            {...row.getRowProps()}
                                                            className="hover-pop"
                                                            key={`new_notification_${i}`}
                                                            borderBottom="1px solid"
                                                            borderColor={"gray.100"}
                                                            cursor="pointer"
                                                            rounded="lg"
                                                            onClick={() => {
                                                                navigateTo(`/notifications/notification/${row.values.id}`,
                                                                    {
                                                                        state: {
                                                                            tabIndex: tabIndex,
                                                                            index: index,
                                                                            grouping: grouping
                                                                        }
                                                                    });
                                                            }}
                                                        >
                                                            {
                                                                row.cells.map(cell => {
                                                                    return <Td {...cell.getCellProps()}>
                                                                        {cell.render("Cell")}
                                                                    </Td>
                                                                })
                                                            }
                                                        </Tr>
                                                    </Fragment>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </Table>
                            </SlideFade>
                    }

                    <Box
                        mt={4}
                        mb={1}
                        textAlign="center"
                        display={pageCount > 1 ? "block" : "none"}
                    >
                        <Pager
                            pageCount={pageCount}
                            pageIndex={index}
                            setPageIndex={setPageIndex}
                        />
                    </Box>
                </Box>
            </Fragment>
        )
    }

    return DataTable({
        data: currentItems,
        columns,
        loading,
        pageCount,
        onHeaderClick: (c: any) => sortColumn(c.id)
    });
}