import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    HStack,
    Icon,
    Image,
    Text,
    Divider,
    Center,
    VStack,
    Button,
} from "@chakra-ui/react";
import {
    BsArrowLeftCircle,
    BsExclamationCircle,
} from "react-icons/bs";

import scratch from "../../assets/images/construction.svg";
import upload from "../../assets/images/uploadRisk.svg";

export const PreCreateRiskAssessment = () => {
    const navigateTo = useNavigate();
    const { state }: any = useLocation();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <Box m={{ base: 0, md: 5 }}>
            <Button
                display={{ base: "none", md: "unset" }}
                w="fit-content"
                size="sm"
                mb={2}
                variant="outline"
                color="gray.600"
                fontWeight={600}
                boxShadow="xs"
                onClick={() => {
                    navigateTo('/riskassessments');
                }}
            >
                <HStack>
                    <Icon as={BsArrowLeftCircle} />
                    <Text>Back to Risk Assessments</Text>
                </HStack>
            </Button>

            <Box
                h={{ base: "full", md: "unset" }}
                pt={2}
                pb={3}
                px={{ base: 0, md: 5 }}
                bg="white"
                rounded={{ base: "none", md: "lg" }}
                boxShadow={{ base: "none", md: "lg" }}
            >
                <HStack p={3} pb={5}>
                    <Icon
                        mr={3}
                        as={BsExclamationCircle}
                        verticalAlign="middle"
                        fontSize="4xl"
                        color="green.500"
                    />

                    <Text
                        fontWeight={{ base: 600, lg: 700 }}
                        fontSize={{ base: "xl", lg: "2xl" }}
                        textAlign="left"
                        color="gray.700"
                    >
                        Create a Risk Assessment
                    </Text>
                </HStack>

                <Divider w="unset" mx={{ base: 0, md: -5 }} />

                <HStack m={5} mt={8} gap={4}>
                    <Box
                        p={5}
                        w="full"
                        cursor="pointer"
                        className="hover-pop"
                        border="1px solid"
                        borderColor="gray.100"
                        borderRadius="lg"
                        boxShadow="sm"
                        onClick={() => {
                            navigateTo("/riskassessments/create", {
                                state: {
                                    type: "create"
                                }
                            });
                        }}
                    >
                        <Center>
                            <VStack>
                                <Image src={scratch} maxH="200px" />
                                <Text
                                    fontWeight={600}
                                    fontSize="xl"
                                    color="gray.700"
                                    mb={5}
                                >
                                    Create From Scratch
                                </Text>
                            </VStack>
                        </Center>
                    </Box>

                    <Box
                        p={5}
                        w="full"
                        cursor="pointer"
                        className="hover-pop"
                        border="1px solid"
                        borderColor="gray.100"
                        borderRadius="lg"
                        boxShadow="sm"
                        onClick={() => {
                            navigateTo("/riskassessments/templateselect", {
                                state: {
                                    returnlocation: "/riskassessments/select",
                                    returntitle: 'Create Risk Assessment',
                                }
                            });
                        }}
                    >
                        <Center>
                            <VStack>
                                <Image src={upload} maxH="200px" />
                                <Text
                                    fontWeight={600}
                                    fontSize="xl"
                                    color="gray.700"
                                    mb={5}
                                >
                                    Create From Template
                                </Text>
                            </VStack>
                        </Center>
                    </Box>
                </HStack>
            </Box>
        </Box>
    );
};