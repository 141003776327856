import { Image, Badge, Box, Button, Center, filter, FormControl, FormLabel, HStack, Icon, Input, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, SlideFade, Spacer, Table, Tbody, Td, Text, Th, Thead, Tr, useBreakpointValue, VStack, Stack } from "@chakra-ui/react";
import { isUndefined } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BsArrowDown, BsArrowLeftCircle, BsArrowUp, BsCheckCircle, BsDash, BsExclamationCircle, BsFiles, BsHandThumbsUp, BsInfoCircle, BsPencil, BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { useTable, useSortBy, usePagination } from "react-table";
import { getCurrentTeams } from "../../features/common/common.actions";
import { cloneRiskAssessmentIntoOrg, getRiskAssessmentTemplates } from "../../features/riskAssessments/riskAssessments.actions";
import { localDate, localDateTimeIgnoreToday } from "../../helpers/DayJsHelper";
import ObbiContainer from '../common/ObbiContainer';
import { Pager } from "../common/Pager";
import { TeamCombobox } from "../common/TeamCombobox";
import { useDebounce } from "../common/useDebounce";
import RATemplateTable from "./RATemplateTable";
import questionGreen from "../../assets/images/questionGreen.svg";
import { SmallFilterCard } from "../common/SmallFilterCard";

export default function CreateRAFromTemplateSelect() {

    const navigateTo = useNavigate();
    const { state }: any = useLocation();

    const [loading, setLoading] = useState<boolean>(true);
    const [RATemplates, setRATemplates] = useState<any[]>([]);
    const [search, setSearch] = useState<string>("");
    const [selectEditingTeamModalisOpen, SetSelectEditingTeamModalisOpen] = useState<boolean>(false);
    const [editingTeams, setEditingTeams] = useState<any>([]);
    const [selectedRiskAssessment, setSelectedRiskAssessment] = useState<any>();
    const [editingTeamId, SetEditingTeamId] = useState<string>("");

    const [categories, setCategories] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [sort, setSort] = useState("");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [firstTimeLoading, setFirstTimeLoading] = useState(true);

    const isMobile = useBreakpointValue({ base: true, md: false });

    const getEditingTeams = () => {
        getCurrentTeams()
            .then((res: any) => {
                setEditingTeams(res?.manages);
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const fetchData = useCallback(async ({ sort, search, category, pageIndex }) => {
        try {
            pageIndex = (pageIndex + 1).toString();

            setLoading(true);
            const res = await getRiskAssessmentTemplates(
                sort,
                search,
                category,
                pageIndex
            );

            setCategories(res.availableCategories);

            setRATemplates(res.riskAssessments);

            setPageCount(res.pageCount);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            setFirstTimeLoading(false);

        }
    }, []);

    useEffect(() => {
        fetchData(
            {
                sort: sort,
                search: search,
                category: selectedCategory,
                pageIndex: pageIndex
            }
        )
        getEditingTeams();
    }, [fetchData, selectedCategory, sort, pageIndex]);

    const updateCategory = (category: string) => {
        setPageIndex(0);
        setSelectedCategory(category);
    };
    const rowClickHandler = async (row: any) => {
        setSelectedRiskAssessment(row);
        SetSelectEditingTeamModalisOpen(true);
    };

    async function handleEditingTeamSelection() {
        try {
            const cloneResult: any = await cloneRiskAssessmentIntoOrg(selectedRiskAssessment.raid, parseInt(editingTeamId));

            if (cloneResult) {
                navigateTo(`/riskassessments/edit/${cloneResult?.newRaId}`, { 
                    state: {
                        isReview: true,
                        step: 1
                    }
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setSelectedRiskAssessment(undefined);
            SetSelectEditingTeamModalisOpen(false);
        }
    }

    const debouncedRequest = useDebounce(() => {
        setPageIndex(0);

        fetchData(
            {
                sort: sort,
                search: search,
                category: selectedCategory,
                pageIndex: 0
            }
        )
    }, 300);

    const updateSearch = (e: any) => {
        setSearch(e.target.value);
        debouncedRequest();
    };

    const sortColumn = (term: string) => {
        switch (term) {
            case "raname":
                if (sort === "raname") {
                    setSort("ranamedesc");
                } else if (sort === "ranamedesc") {
                    setSort("raname");
                } else setSort("ranamedesc");
                break;
            case "racategory":
                if (sort === "racategory") {
                    setSort("racategorydesc");
                } else if (sort === "racategorydesc") {
                    setSort("racategory");
                } else setSort("racategorydesc");
                break;
        }
    };

    return (
        <Box m={{ base: 0, md: 5 }}>

            <ObbiContainer
                icon={BsExclamationCircle}
                title="Select a Risk Assessment Template"
                backLink={
                    <Button
                        display={{ base: "none", md: "unset" }}
                        w="fit-content"
                        size="sm"
                        mb={2}
                        variant="outline"
                        color="gray.600"
                        fontWeight={600}
                        boxShadow="xs"
                        onClick={() => {
                            if (state.returnlocation) {
                                navigateTo(state.returnlocation);
                            } else {
                                navigateTo(-1);
                            }
                        }}
                    >
                        <HStack>
                            <Icon as={BsArrowLeftCircle} />
                            <Text>{state.returntitle ?? 'Back'}</Text>
                        </HStack>
                    </Button>
                }
            >

                <SlideFade
                    in={!firstTimeLoading}
                >
                    {
                        categories?.length > 0 && categories?.length <= 7 && <Box
                            p={1.5}
                            bg="gray.50"
                            textAlign="left"
                            rounded="lg"
                            border="1px solid"
                            borderColor="gray.200"
                        >
                            <Text
                                fontWeight={isMobile ? 600 : 500}
                                color="gray.500"
                                fontSize={isMobile ? "xs" : 'sm'}
                                ml={1}
                                mb={2}
                            >
                                Risk Assessment Categories
                            </Text>

                            <SlideFade in={!firstTimeLoading}>
                                <HStack>
                                    {
                                        categories?.map((c: any, i: Number) => {
                                            return (
                                                <SmallFilterCard
                                                    key={i}
                                                    title={c.racategory}
                                                    onClickAction={() => {
                                                        c.racategory !== selectedCategory
                                                            ? updateCategory(c.racategory)
                                                            : updateCategory("")
                                                    }}
                                                    selectedCard={selectedCategory}
                                                    icon={BsExclamationCircle}
                                                    iconColor="brand.500"
                                                />
                                            );
                                        })
                                    }
                                </HStack>
                            </SlideFade>
                        </Box>
                    }
                </SlideFade>

                <SlideFade
                    in={!firstTimeLoading}
                    offsetY="20px">
                    <Stack
                        direction={{ base: "column", lg: "row" }}
                        mt={
                            categories.length > 0 && categories.length <= 7 ? 2 :
                                categories.length > 7 ? 5 : 5
                        }
                    >
                        {
                            categories?.length > 7 && <>
                                <FormControl w={{ base: "full", lg: "50%" }}>
                                    <Input
                                        placeholder="Search Risk Assessment Templates"
                                        fontSize="sm"
                                        value={search}
                                        onChange={updateSearch}
                                    />
                                </FormControl>
                            </>
                        }
                        {
                            categories?.length > 7 ? <FormControl
                                colorScheme="green"
                                w={{ base: "full", lg: "50%" }}
                            >
                                <Select
                                    _focus={{ borderColor: "brand.500", borderWidth: "1px" }}
                                    color="gray.600"
                                    fontSize="sm"
                                    onChange={(e) => {
                                        updateCategory(e.target.value);
                                    }}
                                    placeholder="All Categories"
                                >
                                    {
                                        categories?.map((c: any, i) => {
                                            return <option key={`category_${i}`} value={c.racategory}>
                                                {c.racategory}
                                            </option>;
                                        })
                                    }
                                </Select>
                            </FormControl> :

                                <FormControl w={{ base: "full", lg: "50%" }}>
                                    <Input
                                        placeholder="Search Risk Assessment Templates"
                                        fontSize="sm"
                                        value={search}
                                        onChange={updateSearch}
                                    />
                                </FormControl>
                        }
                    </Stack>
                </SlideFade>

                {
                    RATemplates?.length > 0
                        && !firstTimeLoading
                        ?
                        <>
                            <Box
                                w="full"
                                p={isMobile ? 0 : 3}
                                mt={5}
                                border={isMobile ? "none" : "1px solid"}
                                borderColor="gray.100"
                                borderRadius="lg"
                            >
                                <RATemplateTable
                                    data={RATemplates ?? []}
                                    loading={loading}
                                    pageCount={pageCount}
                                    rowClickHandler={rowClickHandler}
                                    onHeaderClick={(column: any) => {
                                        sortColumn(column.id)
                                    }
                                    }
                                    sort={sort}
                                    pageIndex={pageIndex}
                                    setPageIndex={setPageIndex}
                                    setSort={setSort}

                                />
                            </Box>
                        </>
                        :

                        <SlideFade in={!loading} offsetY="20px">
                            <Center m={5}>
                                <VStack>
                                    <Text fontSize="xl" fontWeight={600}>
                                        No Risk Assessments Found
                                    </Text>
                                    <Text fontWeight={400} color="gray.500">
                                        You have no risk assessments available
                                    </Text>
                                </VStack>
                            </Center>
                        </SlideFade>
                }

            </ObbiContainer>


            <Modal isOpen={selectEditingTeamModalisOpen} onClose={() => {
                SetSelectEditingTeamModalisOpen(false)
                setSelectedRiskAssessment(undefined);
                SetEditingTeamId('');
            }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirm Selection</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>

                            <HStack mb={2}>
                                <VStack alignItems="start">
                                    <FormLabel
                                        fontWeight={500}
                                        fontSize="sm"
                                        color="gray.600"
                                    >
                                        To create this risk assessment, please select a team to manage it.
                                    </FormLabel>

                                    <Text
                                        fontWeight={500}
                                        fontSize="xs"
                                        color="gray.500"
                                    >
                                        Members of this team are able to make changes to this Risk Assessment prior to publishing.
                                    </Text>
                                </VStack>

                            </HStack>
                            <TeamCombobox
                                teams={editingTeams}
                                update={(e: any) => {
                                    SetEditingTeamId(e);
                                }}
                                hideAllTeams={true}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <HStack>
                            <Button cursor="pointer" size={'sm'} onClick={() => {
                                SetSelectEditingTeamModalisOpen(false)
                                setSelectedRiskAssessment(undefined);
                                SetEditingTeamId('');

                            }}>Cancel</Button>
                            <Button
                                className="hover-pop"
                                colorScheme="brand"
                                size={'sm'}
                                _active={{ bg: "" }}
                                _hover={{ bg: "#248451" }} disabled={editingTeamId === '' || editingTeamId === '0'}
                                onClick={handleEditingTeamSelection}>
                                Confirm
                            </Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Box>
    );


}