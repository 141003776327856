import { getWithParams } from "../../api/axiosClient";

export async function getCertificates(
    sort: string,
    filter: string,
    role: string,
    category: string,
    search: string,
    selectedTeam: string,
    selectedUser: string,
    page: string,
    pagesize: string = "10"
) {
    let certificatesResult: any = {};

    await getWithParams("certificates/all", { sort, filter, role, category, search, team: selectedTeam ?? '', user: selectedUser ?? '', page, pagesize })
        .then((res: any) => {
            certificatesResult = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return certificatesResult;
}

export async function getTeamCertificates(
    orgId: number,
    sort: string,
    filter: string,
    category: string,
    search: string,
    selectedTeam: string,
    selectedUser: string,
    page: string,
    pagesize: string = "10"
) {
    let certificatesResult: any = {};

    await getWithParams("certificates/teamCertificates", { orgId, sort, filter, category, search, team: selectedTeam ?? '', user: selectedUser ?? '', page, pagesize })
        .then((res: any) => {
            certificatesResult = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return certificatesResult;
}

export async function downloadCertificate(id: number, pdf: boolean) {
    let result: any = null;

    await getWithParams("certificates/download", { id, pdf, datauri: true })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}

export async function getCertificateThumbnail(id: number, datauri: boolean = true) {
    let result: any = null;

    await getWithParams("certificates/thumbnail", { id, datauri })
        .then((res: any) => {
            result = res.data;
        })
        .catch((error: any) => {
            throw error;
        });

    return result;
}